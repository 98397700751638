<template>
  <div class="step">
    <el-form :model="step7Form" :rules="rules" ref="step7Form" label-position="left">
      <!-- <el-row class="mt10">
        <span class="gray-color">Дутуу мэдээллийг нөхөж бөглөнө үү</span>
      </el-row> -->
      <el-row type="flex" justify="center" class="m20">
        <title-item title="Дутуу мэдээллийг нөхөж бөглөнө үү" />
      </el-row>
      <el-row type="flex" justify="center">
        <span style="font-size: 12px; font-weight: 600">Гэрээ хийхэд шаардлагатай мэдээлэл</span>
      </el-row>
      <el-row :gutter="50" class="mt50">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="БАЙГУУЛЛАГА, ХУВЬ ХҮНИЙ НЭР" prop="additional_name">
            <el-input placeholder="Нэр" v-model="step7Form.additional_name" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="РЕГИСТРИЙН ДУГААР" prop="additional_register">
            <el-input placeholder="Регистрийн дугаар" v-model="step7Form.additional_register" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="УДИРДЛАГЫН ОВОГ" prop="additional_last_name">
            <el-input placeholder="Овог" v-model="step7Form.additional_last_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="УДИРДЛАГЫН НЭР" prop="additional_first_name">
            <el-input placeholder="Нэр" v-model="step7Form.additional_first_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="ТӨЛӨӨЛЖ ГЭРЭЭ БАЙГУУЛАХ АЖИЛТНЫ НЭР" prop="representative_name">
            <el-input placeholder="Нэр" v-model="step7Form.representative_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="ТӨЛӨӨЛЖ ГЭРЭЭ БАЙГУУЛАХ АЖИЛТНЫ АЛБАН ТУШААЛ" prop="representative_position">
            <el-input placeholder="Албан тушаал" v-model="step7Form.representative_position"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- <div class="footer-container mt80">
      <buttons :validate="validate" :title="'ҮРГЭЛЖЛҮҮЛЭХ'"/>
    </div> -->
    <div class="footer-container mt20">
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
      </el-row>
      <el-row class="mt10 tt-button-text">
        <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
// import Buttons from './buttons.vue'
import Button from '../../../../components/items/button'
import titleItem from '../../../../components/items/titleItem'
export default {
  name: 'step7',
  components: {
    // Buttons,
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    const tempData = JSON.parse(localStorage.getItem('vuex'))
    this.step7Form = this.data
    this.step7Form.additional_name = tempData.registrationStep.steps.step2.company_name
    this.step7Form.additional_register = tempData.registrationStep.steps.step2.register_number
  },
  data () {
    return {
      step7Form: {
        additional_name: '',
        additional_register: '',
        additional_last_name: '',
        additional_first_name: '',
        representative_name: '',
        representative_position: ''
      },
      rules: {
        additional_name: [
          { required: true, message: 'Нэр оруулна уу!', trigger: 'blur' }
        ],
        additional_register: [
          { required: true, message: 'Регистрийн дугаар оруулна уу!', trigger: 'blur' }
        ],
        additional_last_name: [
          { required: true, message: 'Удирдлагын овгийг оруулна уу!', trigger: 'blur' }
        ],
        additional_first_name: [
          { required: true, message: 'Удирдлагын нэрийг оруулна уу!', trigger: 'blur' }
        ],
        representative_name: [
          { required: true, message: 'Төлөөлөгчийн нэрийг оруулна уу!', trigger: 'blur' }
        ],
        representative_position: [
          { required: true, message: 'Төлөөлөгчийн албан тушаал!', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    validate (type) {
      if (type === 'increase') {
        this.$refs.step7Form.validate((valid) => {
          if (valid) {
            this.onComplete('step8', type, this.step7Form)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.onComplete('step6', type, this.step7Form)
      }
    }
  }
}
</script>

<style>
</style>
