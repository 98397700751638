<template>
  <div>
    <login-header />
    <el-row type="flex" justify="center" align="middle" style="height: 100vh; flex-direction: column">
      <title-item title="Баталгаажуулалт" class="mb20"/>
      <el-row type="flex" justify="center">
        <el-form
          :model="model"
          :rules="rules"
          @submit.native.prevent="onSubmit"
          ref="form"
          v-loading="loading"
        >
          <el-form-item prop="code" label="Баталгаажуулах код">
            <input
              style="width: 330px"
              class="inputClass"
              v-model="model.code"
              placeholder="Баталгаажуулах код"
            />
          </el-form-item>
        </el-form>
      </el-row>
      <span style="font-size: 10px; font-weight: 600">
        И-мэйл хаягаар ирсэн баталгаажуулах код оруулна уу.
      </span>
      <el-row type="flex" justify="center">
        <div class="footer-container mt20">
          <Button :loading="loading" title="Баталгаажуулах" native-type="submit" v-on:click.native="onSubmit" />
        </div>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { confirmSignUp } from '../helper/auth'
import loginHeader from '../components/items/loginHeader'
import Button from '../components/items/button.vue'
import titleItem from '../components/items/titleItem.vue'
export default {
  name: 'confirmCode',
  components: {
    loginHeader,
    Button,
    titleItem
  },
  data () {
    return {
      model: {
        code: ''
      },
      loading: false,
      rules: {
        code: [
          {
            required: true,
            message: 'Мэйл хаягт илгээсэн баталгаажуулах кодыг оруулна уу',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          confirmSignUp(this.$route.params.username, this.model.code)
            .then(response => {
              this.loading = false
              this.$message.success('Амжилттай баталгаажлаа')
              this.$alert('Та одоо нэвтэрч ороод хамтран ажиллах мэдээллээ оруулаарай. Бид таны мэдээллийг хүлээн аваад 3 хоногийн дотор эргэж холбогдох болно. Баярлалаа.', 'Амжилттай', {
                customClass: 'customClassBody',
                type: 'success',
                center: true,
                confirmButtonText: 'Ойлголоо'
              })
              this.$router.push({
                name: 'login'
              })
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        } else {
          this.loading = false
          return null
        }
      })
    }
  }
}
</script>
