<template>
  <div>
    <login-header />
    <el-row class="main-container">
      <el-col :span="16" class="left-container">
        <!-- <about-header /> -->
        <el-row type="flex" class="mt50 top-description">
          <img src="../assets/Rectangle 358.png" alt="" style="width: 100%">
          <el-row><span class="black-content">УЛААНБААТАР ХОТЫН</span> <br> <span class="yellow-content">ХАМГИЙН ТОМ ХООЛ ХҮРГЭЛТИЙН АПП</span>
          </el-row>
          <el-row type="flex" class="content-container">
            <el-row type="flex" align="middle" class="three-content">
              <img src="../assets/login/101.png" class="w-85" />
              <el-row type="flex" style="flex-direction: column" align="bottom" class="ml20">
                <div class="bold-text-login">+
                  <count-to :start-val="0" :end-val="800000" :duration="2600" />
                </div>
                <span class="text-20">гаруй хэрэглэгч</span>
              </el-row>
            </el-row>
            <el-row type="flex" align="middle" class="three-content">
              <img src="../assets/login/100.png" class="w-85" />
              <el-row type="flex" style="flex-direction: column" align="bottom" class="ml20">
                <div class="bold-text-login">+
                  <count-to :start-val="0" :end-val="500" :duration="2600" />
                </div>
                <span class="text-20">гаруй харилцагч</span>
              </el-row>
            </el-row>
            <el-row type="flex" align="middle" class="three-content">
              <img src="../assets/login/102.png" class="w-85" />
              <el-row type="flex" style="flex-direction: column" align="bottom" class="ml20">
                <div class="bold-text-login">+
                  <count-to :start-val="0" :end-val="400" :duration="2600" />
                </div>
                <span class="text-20">гаруй хүргэгч</span>
              </el-row>
            </el-row>
          </el-row>
          <el-row class="login-description">
            <span>Ток Ток нь хоол, хүнс, бялуу, кофе цай гэх мэт 4 төрлийн категорид <br> зориулсан технологийн шийдэл, хүргэлтийн үйлчилгээг санал болгодог.</span>
          </el-row>
        </el-row>
      </el-col>
      <el-col :span="8" class="right-container">
        <title-item title="Нэвтрэх" class="mt100"/>
        <el-form v-model="loginForm" ref="loginForm" label-position="top" class="mt30">
          <el-form-item label="Нэвтрэх нэр" prop="login_name">
            <input type="text" v-on:keyup.enter="login(loginForm)" v-model="loginForm.login_name" placeholder="Нэвтрэх нэр" class="inputClass"/>
          </el-form-item>
          <el-form-item label="Нууц үг" prop="password">
            <input show-password v-model="loginForm.password" v-on:keyup.enter="login(loginForm)" type="password" placeholder="Нууц үг" class="inputClass" />
          </el-form-item>
          <div class="text-center">
            <span style="font-size: 10px; color: #9747FF; border-bottom: 1px solid #9747FF; cursor: pointer" @click="centerDialogVisible = true">Нууц үгээ мартсан уу?</span>
          </div>
          <Button class="mt20" :loading="loading" title="Нэвтрэх" icon="" v-on:click.native="login(loginForm)"/>
        </el-form>
      </el-col>
      <el-dialog
        :visible.sync="centerDialogVisible"
        :show-close="false"
        width="355px"
        center>
        <el-row type="flex" justify="center">
          <title-item title="Нууц үг сэргээх" />
        </el-row>
        <el-row class="text-center" style="font-size: 12px; font-weight: 600; color: #3A3A3C; line-height: 18px; word-break: break-word; margin: 20px 0">
          Username оруулна уу. Таны имэйл хаяг руу нууц үг сэргээх код илгээх болно.
        </el-row>
        <el-form
          class="login-form"
          :model="model"
          :rules="rules"
          @submit.native.prevent="onSubmit"
          ref="form"
        >
          <el-form-item label="Username" prop="username">
            <input
              class="inputClass"
              autocomplete="off"
              v-model="model.username"
              placeholder="Username"
              prefix-icon="fas fa-mail-bulk"/>
          </el-form-item>
        </el-form>
        <div style="border-bottom: 2px solid #F8F8F8; margin: 20px 0"></div>
        <Button :loading="loading" title="Илгээх" icon="" v-on:click.native="onSubmit()"/>
      </el-dialog>
    </el-row>
</div>
</template>
<script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import countTo from 'vue-count-to'
import Button from '../components/items/button.vue'
import titleItem from '../components/items/titleItem.vue'
import loginHeader from '../components/items/loginHeader.vue'
import { sendForgotPassCode } from '../helper/auth'
export default {
  name: 'Login',
  components: {
    countTo,
    Button,
    titleItem,
    loginHeader
  },
  data () {
    return {
      centerDialogVisible: false,
      loginForm: {
        login_name: '',
        password: ''
      },
      model: {
        username: ''
      },
      loading: false,
      window: {
        width: 0,
        height: 0
      },
      rules: {
        login_name: [
          { required: true, message: 'Нэвтрэх нэрээ оруулна уу', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Нууц үгээ оруулна уу', trigger: 'blur' }
        ],
        username: [
          {
            required: true,
            message: 'Username хоосон байна',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          sendForgotPassCode(this.model.username)
            .then(response => {
              this.loading = false
              this.$router.push({
                name: 'changePassword',
                params: {
                  username: this.model.username,
                  email: response.CodeDeliveryDetails.Destination
                }
              })
            })
            .catch(error => {
              const tempErrorData = JSON.parse(JSON.stringify(error))
              this.loading = false
              if (tempErrorData.name === 'UserNotFoundException') {
                this.$message.error('Бүртгэлгүй и-мэйл хаяг байна.')
              } else if (tempErrorData.name === 'LimitExceededException') {
                this.$message.error('Таны эрх түр хугацаанд хаагдлаа. Хэсэг хугацааны дараа дахин оролдлого хийнэ үү.')
              } else {
                this.$message.error(tempErrorData.name)
              }
            })
        } else {
          return null
        }
      })
    },
    async login (login) {
      if (!['', null, undefined].includes(login.login_name)) {
        if (!['', null, undefined].includes(login.password)) {
          this.loading = true
          Auth.signIn(login.login_name, login.password).then((user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              user.completeNewPasswordChallenge(login.password).then(() => {
                this.loading = false
              }).catch(error => {
                console.log(error)
                this.loading = false
              })
            } else {
              if (user) {
                localStorage.setItem('clientData', JSON.stringify(user.attributes))
                AmplifyEventBus.$emit('authState', 'signedIn')
              }
              this.loading = false
            }
          }).catch((err) => {
            this.$message.error(err.message)
            this.loading = false
          })
        } else {
          this.$message.error('Нууц үг оруулна уу')
        }
      } else {
        this.$message.error('И-мэйл оруулна уу')
      }
    }
  }
}
</script>
