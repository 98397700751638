import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import registrationStep from './modules/registrationStep'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    isShowNoSalesPartnerModal: false,
    noSalesPartnerData: []
  },
  mutations: {
    isShow (state, isShow) {
      state.isShowNoSalesPartnerModal = isShow
    },
    noSalesPartnerData (state, data) {
      state.noSalesPartnerData = data
    }
  },
  actions: {
  },
  getters: {
  },
  modules: {
    registrationStep
  },
  plugins: [vuexLocal.plugin]
})
