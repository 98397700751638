<template>
  <div class="backgroundImage">
    <create-partner-header />
    <el-row class="user-login-container" type="flex" justify="center" align="middle" style="flex-direction: column">
      <el-row type="flex" justify="center" style="width: 100%">
        <span class="border-bottom-1">Нууц үг сэргээх</span>
      </el-row>
      <el-row type="flex" justify="center" class="text-color-secondary mb20 mt20">
        <span style="font-size: 12px; text-align: center">
          Бүртгэлтэй и-мэйл хаягаа оруулна уу. <br> Таны имэйл хаяг руу нууц үг
          сэргээх код илгээх болно.
        </span>
      </el-row>
      <el-row type="flex" justify="center" class="mt30">
        <el-form
          class="login-form"
          :model="model"
          :rules="rules"
          @submit.native.prevent="onSubmit"
          ref="form"
        >
          <el-form-item prop="username" label="И-мэйл хаяг">
            <el-input
              v-model="model.username"
              placeholder="Бүртгэлтэй и-мэйл хаяг"
              prefix-icon="fas fa-mail-bulk"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row type="flex" justify="center">
        <div class="footer-container mt30">
          <el-row>
            <el-button :loading="loading" type="warning" class="tt-button-yellow" native-type="submit" @click="onSubmit()">Илгээх</el-button>
          </el-row>
        </div>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { sendForgotPassCode } from '../helper/auth'
import createPartnerHeader from '../components/createPartnerHeader.vue'
export default {
  name: 'forgotPassword',
  components: {
    createPartnerHeader
  },
  data () {
    return {
      model: {
        username: ''
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: 'Нэвтрэх нэр хоосон байна',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          sendForgotPassCode(this.model.username)
            .then(response => {
              this.loading = false
              this.$router.push({
                name: 'changePassword',
                params: {
                  username: this.model.username
                }
              })
            })
            .catch(error => {
              this.loading = false
              this.$message.error(error)
            })
        } else {
          return null
        }
      })
    }
  }
}
</script>
<style scoped>
</style>
