<template>
  <div class="step">
    <el-form :model="step5Form" :rules="rules" ref="step5Form">
      <!-- <el-row class="mt10">
        <span>Холбоо барих мэдээлэл</span>
      </el-row> -->
      <el-row type="flex" justify="center" class="m20">
        <title-item title="Холбоо барих мэдээлэл" />
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="УДИРДЛАГЫН И-МЭЙЛ" prop="ceo_email">
            <el-input placeholder="И-мэйл хаяг" v-model="step5Form.ceo_email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="УДИРДЛАГЫН УТАСНЫ ДУГААР" prop="ceo_phone">
            <el-input placeholder="Утасны дугаар" type="number" v-model="step5Form.ceo_phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="МЕНЕЖЕР И-МЭЙЛ" prop="manager_email">
            <el-input placeholder="И-мэйл хаяг" v-model="step5Form.manager_email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="МЕНЕЖЕР УТАСНЫ ДУГААР" prop="manager_phone">
            <el-input placeholder="Утасны дугаар" type="number" v-model="step5Form.manager_phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="САНХҮҮ И-МЭЙЛ" prop="finance_email">
            <el-input placeholder="И-мэйл хаяг" v-model="step5Form.finance_email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="САНХҮҮ УТАСНЫ ДУГААР" prop="finance_phone">
            <el-input placeholder="Утасны дугаар" type="number" v-model="step5Form.finance_phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="ЗАХИАЛГЫН И-МЭЙЛ" prop="order_email">
            <el-input placeholder="И-мэйл хаяг" v-model="step5Form.order_email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="ЗАХИАЛГЫН УТАСНЫ ДУГААР" prop="order_phone">
            <el-input placeholder="Утасны дугаар" type="number" v-model="step5Form.order_phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer-container mt20">
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
      </el-row>
      <el-row class="mt10 tt-button-text">
        <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
      </el-row>
    </div>
    <!-- <div class="footer-container mt80">
      <buttons :validate="validate" :title="'ҮРГЭЛЖЛҮҮЛЭХ'"/>
    </div> -->
  </div>
</template>

<script>
// import Buttons from './buttons.vue'
import Button from '../../../../components/items/button'
import titleItem from '../../../../components/items/titleItem'
export default {
  name: 'step5',
  components: {
    // Buttons,
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step5Form = this.data
  },
  data () {
    return {
      step5Form: {
        ceo_email: '',
        ceo_phone: '',
        manager_email: '',
        manager_phone: '',
        finance_email: '',
        finance_phone: '',
        order_email: '',
        order_phone: ''
      },
      rules: {
        ceo_email: [
          { required: true, message: 'И-мэйл хаягаа оруулна уу!', trigger: 'blur' },
          { type: 'email', message: 'Та стандарт и-мэйл хаяг оруулна уу?', trigger: ['blur', 'change'] }
        ],
        ceo_phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу!', trigger: 'blur' },
          { min: 8, max: 11, message: 'Та багадаа 8 оронтой утасны дугаар оруулна уу?', trigger: 'blur' }
        ],
        manager_email: [
          { required: true, message: 'И-мэйл хаягаа оруулна уу!', trigger: 'blur' },
          { type: 'email', message: 'Та стандарт и-мэйл хаяг оруулна уу?', trigger: ['blur', 'change'] }
        ],
        manager_phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу!', trigger: 'blur' },
          { min: 8, max: 11, message: 'Та багадаа 8 оронтой утасны дугаар оруулна уу?', trigger: 'blur' }
        ],
        finance_email: [
          { required: true, message: 'И-мэйл хаягаа оруулна уу!', trigger: 'blur' },
          { type: 'email', message: 'Та стандарт и-мэйл хаяг оруулна уу?', trigger: ['blur', 'change'] }
        ],
        finance_phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу!', trigger: 'blur' },
          { min: 8, max: 11, message: 'Та багадаа 8 оронтой утасны дугаар оруулна уу?', trigger: 'blur' }
        ],
        order_email: [
          { required: true, message: 'И-мэйл хаягаа оруулна уу!', trigger: 'blur' },
          { type: 'email', message: 'Та стандарт и-мэйл хаяг оруулна уу?', trigger: ['blur', 'change'] }
        ],
        order_phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу!', trigger: 'blur' },
          { min: 8, max: 11, message: 'Та багадаа 8 оронтой утасны дугаар оруулна уу?', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    validate (type) {
      if (type === 'increase') {
        this.$refs.step5Form.validate((valid) => {
          if (valid) {
            this.onComplete('step6', type, this.step5Form)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.onComplete('step4', type, this.step5Form)
      }
    }
  }
}
</script>

<style>
</style>
