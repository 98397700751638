<template>
  <el-dialog
    title="Анхааруулга!"
    :visible.sync="isShowModal"
    width="300px"
    :before-close="closeModal">
    <el-row style="word-break: break-word">
      <el-row type="flex" justify="center">
        <span class="text-center text-word-break descriptionWarning">
          Таны борлуулж буй аливаа бараа, бүтээгдэхүүн нь QR кодтой
          И-баримтын хамтаар хэрэглэгчид хүргэгдэх шаардлагатай байдаг шүү.
        </span>
      </el-row>
      <el-row type="flex" justify="center" class="mt20 text-center">
        <strong>ӨӨ, ТА ТАТВАР ТӨЛӨГЧ БОЛООД ЭРГЭЭД ХОЛБОГДООРОЙ ЗА.</strong>
      </el-row>
      <el-row type="flex" justify="center" class="mt20 text-center">
        <span>Доорх линкээр ороод татвар төлөгч болох хүсэлтээ өгөөрэй.</span>
      </el-row>
      <el-row type="flex" justify="center" class="mt10">
        <el-button type="warning"><a href="https://itax.mta.mn" target="_blank">ХҮСЭЛТ ИЛГЭЭХ</a></el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'warningModal',
  props: {
    isShowModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => null
    }
  }
}
</script>

<style>
.el-button a {
  color: white
}
.descriptionWarning {
  background-color: #FFB500;
  padding: 10px;
  border-radius: 10px;
  color: white;
}
</style>
