import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/MainLayout.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import About from '../views/about.vue'
import Contact from '../views/contact.vue'
import changePassword from '../views/changePassword.vue'
import forgotPassword from '../views/forgotPassword.vue'
import ConfirmCode from '../views/confirmCode.vue'
import newPasswordRequired from '../views/newPasswordRequired.vue'
import createPartner from '../views/pages/createPartner/createPartner.vue'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { getOutlet, getUser } from '../helper/auth'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/pages/home/Home.vue')
      },
      {
        path: '/products',
        name: 'products',
        component: () => import('../views/pages/product/Products.vue')
      },
      {
        path: '/outlet',
        name: 'outlet',
        component: () => import('../views/pages/product/components/mobile/OutletList.vue')
      },
      {
        path: '/category',
        name: 'category',
        component: () => import('../views/pages/product/components/mobile/CategoryProducts.vue')
      },
      {
        path: '/settlement',
        name: 'settlement',
        component: () => import('../views/pages/settlement/Settlement.vue')
      },
      {
        path: '/settlementAct',
        name: 'settlementAct',
        component: () => import('../views/pages/settlement/settlementAct.vue')
      },
      {
        path: '/info',
        name: 'info',
        component: () => import('../views/pages/info/Info.vue')
      },
      {
        path: '/delivery-zone',
        name: 'deliveryZone',
        component: () => import('../views/pages/deliveryZone/DeliveryZone.vue')
      },
      {
        path: '/complain',
        name: 'complain',
        component: () => import('../views/pages/complain/Complain.vue')
      },
      {
        path: '/timetable',
        name: 'timetable',
        component: () => import('../views/pages/timetable/Timetable.vue')
      },
      {
        path: '/phone',
        name: 'phone',
        component: () => import('../views/pages/phone/phone.vue')
      },
      {
        path: '/invoice',
        name: 'invoice',
        component: () => import('../views/pages/invoice/invoice.vue')
      },
      {
        path: '/marketing',
        name: 'marketing',
        component: () => import('../views/pages/marketing/marketing.vue')
      }
      // {
      //   path: '/productHistory',
      //   name: 'productHistory',
      //   component: () => import('../views/pages/history/ProductHistory.vue')
      // },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/create-partner',
    name: 'createPartner',
    component: createPartner,
    meta: { requiresAuth: true }
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword
  },
  {
    path: '/confirmCode',
    name: 'confirmCode',
    component: ConfirmCode
  },
  {
    path: '/newPasswordRequired',
    name: 'newPasswordRequired',
    component: newPasswordRequired
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: forgotPassword
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
AmplifyEventBus.$on('authState', async state => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/login' })
    },
    signUp: () => {
      router.push({ path: '/signUp' })
    },
    signIn: () => {
      router.push({ path: '/home' })
    },
    newPasswordRequired: () => {
      router.push({ path: '/newPasswordRequired' })
    },
    signedIn: async () => {
      if (await isAdmin()) {
        router.push({ path: '/home' })
      } else {
        const getOutletID = await getOutlet()
        if (!getOutletID) {
          router.push({ path: '/create-partner' })
        } else {
          router.push({ path: '/home' })
          localStorage.setItem('isShowNoSalesDailog', true)
        }
      }
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})
router.beforeResolve(async (to, from, next) => {
  const currentAuthenticatedUser = await getUser()
  if (currentAuthenticatedUser) {
    if (to.path === '/newPasswordRequired') return next()
    if (await isAdmin()) {
      return next()
    }
    if (to.path === '/create-partner') return next()
    const getOutletID = await getOutlet()
    if (!getOutletID && Object.prototype.hasOwnProperty.call(localStorage, 'vuex') && JSON.parse(localStorage.getItem('vuex')).registrationStep.steps.step2 !== null) {
      return next({ path: '/create-partner' })
    } else if (!getOutletID && to.path !== '/create-partner') {
      return next({ path: '/create-partner' })
    } else if (to.path === '/login' && getOutletID) {
      return next({ path: 'home' })
    } else {
      return next()
    }
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      return next({ path: '/login' })
    }
  }
  return next()
})
const isAdmin = async () => {
  const currentAuthenticatedUser = await getUser()
  if (Object.prototype.hasOwnProperty.call(currentAuthenticatedUser.signInUserSession.accessToken.payload, 'cognito:groups') && currentAuthenticatedUser.signInUserSession.accessToken.payload['cognito:groups'].includes('admin')) {
    return true
  }
  return false
}

export default router
