<template>
  <div class="header-container">
    <el-row type="flex" justify="space-between" class="login-main-header" :style="$route.path === '/about' || $route.path === '/contact' || $route.path === '/confirmCode' || $route.path === '/create-partner' ? 'background-color: #fff; border-bottom: 2px solid #E6E6E6' : ''">
      <el-col :span="16">
        <ul class="loginHeader">
          <li>
            <router-link :to="{ path: '/login' }">
              <img src="../../assets/logo.png" class="img" />
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/about' }">
              <span :class="$route.path === '/about' ? 'header-bottom-border' : ''">Бидний тухай</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/contact' }">
              <span :class="$route.path === '/contact' ? 'header-bottom-border' : ''">Холбоо барих</span>
            </router-link>
          </li>
        </ul>
      </el-col>
      <el-col :span="8">
        <el-row type="flex" justify="end" style="gap: 10px">
          <router-link :to="{ path: '/signUp' }" v-if="$route.path !== '/signUp' && $route.path !== '/create-partner'">
            <Button title="Бүртгүүлэх" icon="el-icon-plus" style="font-weight: 500"/>
          </router-link>
          <router-link :to="{ path: '/login' }" v-if="$route.path !== '/login' && $route.path !== '/create-partner'">
            <Button title="Нэвтрэх" icon="el-icon-user" style="font-weight: 500"/>
          </router-link>
          <Button title="Гарах" icon="el-icon-close" v-on:click.native="logout" style="width: auto" v-if="$route.path === '/create-partner'"/>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between" class="mobile-login-header">
      <router-link :to="{ path: '/login' }">
        <img src="../../assets/logo.png" />
      </router-link>
      <el-dropdown  @command="handleItem">
        <div style="display: flex; justify-content: space-between; flex-direction: column; cursor: pointer; gap: 5px">
          <div style="background-color: #3A3A3C; height: 3px; width: 24px"></div>
          <div style="background-color: #3A3A3C; height: 3px; width: 24px"></div>
          <div style="background-color: #3A3A3C; height: 3px; width: 24px"></div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="about">Бидний тухай</el-dropdown-item>
          <el-dropdown-item command="signup">Бүртгүүлэх</el-dropdown-item>
          <el-dropdown-item command="login">Нэвтрэх</el-dropdown-item>
          <el-dropdown-item v-if="$route.path === '/create-partner'" command="logout">Гарах</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <user-progress v-if="$route.path === '/create-partner'" :percentage="percentage"/>
  </div>
</template>

<script>
import Button from './button.vue'
import userProgress from '../../views/pages/createPartner/components/progress.vue'
import { signOut } from '../../helper/auth'
export default {
  name: 'LoginHeader',
  props: {
    percentage: {
      type: Number,
      default: 100
    }
  },
  components: {
    Button,
    userProgress
  },
  methods: {
    async logout () {
      signOut()
    },
    handleItem (item) {
      if (item === 'signup') {
        this.$router.push({ name: 'SignUp' })
      } else if (item === 'login') {
        this.$router.push({ name: 'login' })
      } else if (item === 'logout') {
        this.logout()
      } else if (item === 'about') {
        this.$router.push({ name: 'About' })
      } else {
        this.$router.push({ path: '/contact' })
      }
    }
  }
}
</script>
