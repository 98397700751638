<template>
  <div>
    <login-header />
    <el-row class="main-container">
      <el-col :span="16" class="left-container">
        <el-row type="flex" class="mt50 top-description">
          <img src="../assets/Rectangle.png" alt="" style="width: 100%">
          <el-row><span class="black-content">ТОК ТОК-ТОЙ</span> <br> <span  class="yellow-content">НЭГДСЭНЭЭР</span>
          </el-row>
          <sign-up-item />
        </el-row>
      </el-col>
      <el-col :span="8" class="right-container">
        <title-item title="Бүртгүүлэх" class="mt80"/>
        <div class="confirm-container" v-if="isRegister">
          <el-row style="width: 100%" type="flex">
            <div :class="human ? 'nonActiveBtn' : 'activeBtn'" style="display: flex; align-items:center; justify-content: center" @click="handleClick('company')">ААН</div>
            <div :class="human ? 'activeBtn' : 'nonActiveBtn'" style="display: flex; align-items:center; justify-content: center" @click="handleClick('human')">Иргэн</div>
          </el-row>
          <el-form :model="checkRegister" ref="checkRegister" :rules="rulesRegister" class="mt20">
            <div class="mb10">
              <span style="font-size: 10px; font-weight: 600; color: #3A3A3C">Та регистрийн дугаараа оруулна уу.</span>
            </div>
            <el-form-item v-if="!human" prop="register">
              <input v-model="checkRegister.register" placeholder="Байгууллагын регистрийн дугаар" @change="checkRegisterFunc('checkRegister')" class="inputClass"/>
            </el-form-item>
            <el-form-item v-else-if="human" prop="registerNumber">
              <el-row type="flex" style="gap: 5px">
                <input class="inputClass" maxlength="1" v-model="firstLetter" placeholder="А" style="width: 20px"/>
                <input class="inputClass" maxlength="1" v-model="secondLetter" placeholder="А" style="width: 20px"/>
                <input maxlength="8" type="number" v-model="checkRegister.registerNumber" placeholder="Регистрийн дугаар" @change="checkRegisterFunc('checkRegister')" class="inputClass" />
              </el-row>
            </el-form-item>
          </el-form>
          <Button :loading="loading" type="warning" class="tt-button-yellow" v-on:click.native="checkRegisterFunc('checkRegister')" title="Шалгах"/>
        </div>
        <el-form v-else label-position="top" class="mt20" @submit.native.prevent="onSubmit" :model="model" :rules="rules" ref="form">
          <el-form-item label="Регистрийн дугаар">
            <el-input style="color: black;" type="text" placeholder="Регистрийн дугаар" disabled v-model="registerdata.register">
              <template slot="append">
                <el-button @click="changeRegisterNumber">Солих</el-button>
              </template>
            </el-input>
            <el-input class="mt20" disabled v-model="registerdata.company"/>
          </el-form-item>
          <el-form-item label="Нэвтрэх нэр"  prop="username">
            <input type="text" style="margin-bottom: 2%;" placeholder="Нэвтрэх нэр" @keyup="inputContainsSpecialCharacters" :class="isCyrillic ? 'validClass' : 'inputClass'" autocomplete="off" v-model="model.username"/>
            <!-- <div style="color:#fc7484; margin-bottom: 4%; font-size:12px;" v-if="isCharacter">Та тэмдэгт ашиглахгүй бичнэ үү!!!</div> -->
            <div style="color:#fc7484; font-size:12px;" v-if="isCyrillic">Та зөвхөн латинаар бичнэ үү!!!</div>
          </el-form-item>
          <el-form-item label="И-мэйл хаяг" prop="email">
            <input type="email" placeholder="И-мэйл хаяг" class="inputClass" autocomplete="off" v-model="model.email"/>
          </el-form-item>
          <el-form-item label="Нууц үг" prop="password">
            <input show-password type="password" placeholder="Нууц үг" class="inputClass" autocomplete="new-password" v-model="model.password"/>
          </el-form-item>
          <el-form-item label="Нууц үг давтах" prop="confirmPassword">
            <input show-password type="password" placeholder="Нууц үг давтах" class="inputClass" autocomplete="new-password" v-model="model.confirmPassword"/>
          </el-form-item>
          <Button class="mt20" title="Бүртгүүлэх" v-on:click.native="onSubmit" :loading="loading"/>
        </el-form>
      </el-col>
    </el-row>
    <warning-modal :isShowModal="isShowModal" :close-modal="handleClose"/>
  </div>
  <!-- <div class="backgroundImage">
    <login-header />
    <div class="user-login-container">
      <el-row type="flex" align="middle">
        <el-col :span="18" class="hidden-sm-only hidden-xs-only">
          <el-row type="flex" justify="center" style="flex-direction: column; height: 100vh; gap: 0rem">
            <el-row type="flex" justify="center">
              <span class="bold-text-login text-50">ТОК ТОК-ТОЙ <strong style="background: #FFB500; padding: 0 10px 0 10px; border-radius: 30px">НЭГДСЭНЭЭР</strong></span>
            </el-row>
            <el-row type="flex" justify="space-around" align="middle" :gutter="20">
              <el-col :span="5">
                <el-row type="flex" style="flex-direction: column" align="middle">
                  <img src="../assets/signUp/300.png" class="w-250" />
                  <span class="bold-text-login text-20 text-center">БОРЛУУЛАЛТАА <br> НЭМЭГДҮҮЛЭХ</span>
                  <span class="text-14 bold-text-login">Шинэ суваг, нэмэлт орлого</span>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row type="flex" style="flex-direction: column" align="middle">
                  <img src="../assets/signUp/301.png" class="w-250" />
                  <span class="bold-text-login text-20 text-center">БРЭНДЭЭ <br> ТАНИУЛАХ</span>
                  <span class="text-14 bold-text-login">+800k хэрэглэгчдэд сурталчлах</span>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row type="flex" style="flex-direction: column" align="middle">
                  <img src="../assets/signUp/302.png" class="w-250" />
                  <span class="bold-text-login text-20 text-center">ХҮРГЭЛТИЙН <br> ҮЙЛЧИЛГЭЭ</span>
                  <span class="text-14 bold-text-login">Ток Ток хүргэх / Харилцагч хүргэх</span>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-around" align="middle" :gutter="20">
              <el-col :span="5">
                <el-row type="flex" style="flex-direction: column" align="middle">
                  <img src="../assets/signUp/303.png" class="w-250" />
                  <span class="bold-text-login text-20 text-center">ХАМТАРСАН <br> ИДЭВХЖҮҮЛЭЛТ</span>
                  <span class="text-14 bold-text-login">Борлуулалтыг дэмжих нэмэлт ажлууд</span>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row type="flex" style="flex-direction: column" align="middle">
                  <img src="../assets/signUp/304.png" class="w-250" />
                  <span class="bold-text-login text-20 text-center">БОРЛУУЛАЛТЫН <br> МЭДЭЭЛЛЭЭ ХЯНАХ</span>
                  <span class="text-14 bold-text-login">Удирдлагын болон захиалгын автомат систем</span>
                </el-row>
              </el-col>
              <el-col :span="5">
                <el-row type="flex" style="flex-direction: column" align="middle">
                  <img src="../assets/signUp/305.png" class="w-250" />
                  <span class="bold-text-login text-20 text-center">МАРКЕТИНГИЙН <br> ҮЙЛЧИЛГЭЭ</span>
                  <span class="text-14 bold-text-login">Баннер, мессеж, контент</span>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </el-col>
        <el-col :span="6" :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
          <el-row type="flex" justify="center" style="flex-direction: column; padding: 80px 60px 80px 60px">
            <el-form
              @submit.native.prevent="onSubmit"
              class="login-form"
              :model="model"
              :rules="rules"
              ref="form"
              autocomplete="off"
            >
              <el-form-item prop="username" label="Нэвтрэх нэр">
                <el-row type="flex" align="middle">
                  <span slot="label">И-мэйл хаяг</span>
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="Нэвтрэх нэрийг хоосон зай авч болохгүйг анхаарна уу!">
                    <i class="el-icon-info cursor-pointer" slot="reference" style="font-size: 18px"></i>
                  </el-popover>
                </el-row>
                <el-input
                  v-model="model.username"
                  placeholder="Нэвтрэх нэр"
                  prefix-icon="fas fa-user"
                ></el-input>
              </el-form-item>
              <el-form-item prop="email" label="И-мэйл хаяг">
                <el-row type="flex" align="middle">
                  <span slot="label">И-мэйл хаяг</span>
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="Та цаашид энэ и-мэйл хаягыг ашиглах тул идэвхтэй и-мэйл хаяг оруулна уу.">
                    <i class="el-icon-info cursor-pointer" slot="reference" style="font-size: 18px"></i>
                  </el-popover>
                </el-row>
                <el-input
                  v-model="model.email"
                  placeholder="И-мэйл"
                  type="email"
                  prefix-icon="fas fa-user"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password" label="Нууц үг">
                <el-row type="flex" align="middle">
                  <span slot="label">Нууц үг</span>
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="8 тэмдэгтээс дээш нууц үг оруулхыг анхаарна уу!">
                    <i class="el-icon-info cursor-pointer" slot="reference" style="font-size: 18px"></i>
                  </el-popover>
                </el-row>
                <el-input
                  v-model="model.password"
                  placeholder="Нууц үг"
                  type="password"
                  show-password
                  prefix-icon="fas fa-lock"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
              <el-form-item prop="confirmPassword" label="Нууц үг давтах">
                <el-row type="flex" align="middle">
                  <span slot="label">Нууц үг давтах</span>
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="8 тэмдэгтээс дээш нууц үг оруулхыг анхаарна уу!">
                    <i class="el-icon-info cursor-pointer" slot="reference" style="font-size: 18px"></i>
                  </el-popover>
                </el-row>
                <el-input
                  v-model="model.confirmPassword"
                  placeholder="Нууц үг давтах"
                  type="password"
                  show-password
                  prefix-icon="fas fa-lock"
                  autocomplete="new-password"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-row type="flex" justify="center">
              <el-button :loading="loading" type="warning" class="tt-button-yellow" @click="onSubmit">Бүртгүүлэх</el-button>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div> -->
</template>
<script>
import { signUp } from '../helper/auth'
import warningModal from '../components/modals/warningModal.vue'
import Button from '../components/items/button.vue'
import titleItem from '../components/items/titleItem.vue'
import loginHeader from '../components/items/loginHeader.vue'
import signUpItem from '../components/items/signUpItem.vue'
import axios from 'axios'

export default {
  name: 'SignUp',
  components: {
    Button,
    titleItem,
    loginHeader,
    signUpItem,
    warningModal
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Та нууц үгээ оруулна уу!'))
      } else {
        if (this.model.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    const checkUserName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Та нэвтрэх нэрээ оруулна уу!'))
      } else if (value.includes(' ')) {
        callback(new Error('Хоосон зай оруулж болохгүйг анхаарна уу!'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Та нууц үгээ давтан оруулна уу!'))
      } else if (value !== this.model.password) {
        callback(new Error('Нууц үг адилхан байх ёстой.'))
      } else {
        callback()
      }
    }
    return {
      isCharacter: false,
      isCyrillic: false,
      isRegister: true,
      human: false,
      checkRegister: {
        register: '',
        registerNumber: ''
      },
      registerdata: {
        register: '',
        company: ''
      },
      vat_type: '',
      firstLetter: '',
      secondLetter: '',
      isShowModal: false,
      rulesRegister: {
        register: [
          { required: true, message: 'Регистрийн дугаар оруулна уу?', trigger: 'blur' }
        ],
        registerNumber: [
          { required: true, message: 'Регистрийн дугаар оруулна уу?', trigger: 'blur' }
        ]
      },
      loading: false,
      validCredentials: {
        username: 'lightscope',
        email: 'lightscope',
        password: 'lightscope'
      },
      model: {
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        username: [
          {
            required: true,
            validator: checkUserName,
            trigger: ['blur', 'change']
          }
        ],
        email: [
          { required: true, message: 'И-мэйл хаягаа оруулна уу?', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, validator: validatePass, trigger: ['blur', 'change'] },
          {
            required: true,
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: ['blur', 'change']
          }
        ],
        confirmPassword: [
          { validator: validatePass2, trigger: ['blur', 'change'] },
          {
            required: true,
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    inputContainsSpecialCharacters () {
      // const specialCharacters = /[`!@#$%^&*()_+\-=\\|,.<>?~./\]/
      const cyrillicPattern = /[а-яА-ЯЁё]/
      // this.isCharacter = specialCharacters.test(
      //   this.model.username
      // )
      this.isCyrillic = cyrillicPattern.test(
        this.model.username
      )
    },
    handleClose () {
      this.isShowModal = false
    },
    changeRegisterNumber () {
      this.isRegister = true
    },
    handleClick (type) {
      if (type === 'company') {
        this.human = false
        this.checkRegister.registerNumber = ''
        this.firstLetter = ''
        this.secondLetter = ''
      } else {
        this.human = true
        this.checkRegister.register = ''
      }
    },
    checkRegisterFunc (formName) {
      let regNo = ''
      if (this.human) {
        regNo = `${this.firstLetter + this.secondLetter + this.checkRegister.registerNumber}`
      } else {
        regNo = this.checkRegister.register
      }
      this.registerdata.register = regNo
      this.$refs[formName].validate((valid) => {
        this.loading = true
        if (valid) {
          axios.get('https://rest.toktok.mn/client-api-test/no-auth/settlement/checkEbarimt?reg_no=' + regNo).then(response => {
            if (response.data.status === 'success' && response.data.data.error === null) {
              this.registerdata.company = response.data.data.data
              this.isShowModal = false
              this.isRegister = false
              this.loading = false
            } else {
              this.isShowModal = true
              this.isRegister = true
              this.loading = false
            }
          })
        } else {
          this.loading = false
          return false
        }
      })
    },
    onSubmit () {
      if (this.isCyrillic) {
        this.$notify({
          type: 'warning',
          title: 'Анхааруулга',
          message: 'Та нэвтрэх нэрээ шалгана уу!!!',
          duration: 2000
        })
      } else {
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.loading = true
            signUp(
              this.model.username,
              this.model.email,
              this.model.password
            ).then(response => {
              if (response !== undefined) {
                this.loading = false
                this.$message.success({
                  message: 'Амжилттай',
                  type: 'success'
                })
                this.$router.push({
                  name: 'confirmCode',
                  params: {
                    username: response.user.username
                  }
                })
                localStorage.setItem('registerData', JSON.stringify(this.registerdata))
              } else {
                this.loading = false
              }
            }).catch(err => {
              this.loading = false
              console.log('err', err.message)
              this.$message.error({ message: 'Алдаа гарлаа' })
            })
          }
        })
      }
    }
  }
}
</script>
<style>
.el-form-item__content {
  line-height: 0px !important;
}
.el-form-item__label {
  padding: 0 5px 12px 0 !important;
}
.el-popover {
  word-break: break-word !important;
}
.loginPage {
  height: 100vh;
  width: 100%;
}
.activeBtn {
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  height: 44px;
  width: 50%;
  border-bottom: 2px solid #7CBB00;
  color: #3A3A3C;
}
.nonActiveBtn {
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  height: 44px;
  width: 50%;
  color: #E6E6E6;
  border-bottom: 2px solid #E6E6E6;
}
.register {
  width:15%;
  margin-right:3%;
}
.validClass {
  color: #231f20;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid red;
  border-radius: 12px;
  height: 50px;
  padding-left: 10px;
  width: 95%;
}
.validClass:focus {
  border: 1px solid red;
  outline: red;
}
</style>
