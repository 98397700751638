<template>
  <button class="login-button" type="button">
    <i :class="icon" style="font-weight: 700"></i>
    <i class="el-icon-loading" v-if="loading"/>
    <span :class="icon || loading !== '' ? 'ml10' : ''">{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
</style>
