<template>
  <div class="loginPage backgroundImg">
    <el-row :gutter="0" style="margin-top: 15%; margin-left: 2%;">
      <el-col :span="4">
        <div class="panel" style="width: 22vw">
          <div class="panel-item">
            <header>Баталгаажуулах</header>
          </div>
          <div class="panel-item">
            <div class="text-color-secondary mb20">
              Шинэ нууц үгээ хийнэ үү
            </div>
            <el-form
              class="login-form"
              :model="model"
              :rules="rules"
              @submit.native.prevent="onSubmit"
              ref="form"
              v-loading="loading"
            >
              <el-form-item prop="password" label="Шинэ нууц үгээ хийнэ үү:">
                <el-input
                  v-model="model.password"
                  placeholder=""
                  prefix-icon="fas fa-lock-open"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="login-button block"
                  type="primary"
                  native-type="submit"
                  @click="onSubmit()"
                  >Баталгаажуулах</el-button
                >
              </el-form-item>
              <div class="text-center">
                <router-link class="inventory" to="/login"
                  >Нэвтрэх</router-link
                >
              </div>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { completeNewPassword } from '../helper/auth'
export default {
  name: 'newPasswordRequired',
  data () {
    return {
      model: {
        password: ''
      },
      loading: false,
      rules: {
        password: [
          {
            required: true,
            message: 'Баталгаажуулах код хоосон байна',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          completeNewPassword(this.model.password)
            .then(response => {
              this.loading = false
              this.$router.push({
                name: 'login'
              })
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        } else {
          this.loading = false
          return null
        }
      })
    }
  }
}
</script>
<style scoped>
.loginPage {
  display: flex;
  height: 100vh;
  width: 100%;
}
#zxc {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-image: url("../assets/clientBG.jpg");
}
</style>
