<template>
  <div class="step">
    <el-form :model="step2Form" :rules="rules" ref="step2Form">
      <el-row type="flex" justify="center" class="step2-desc"><strong><span style="color: orange">Nice,</span> Та ТОК ТОК-той хамтарч ажиллах боломжтой байна. Үргэлжлүүлэн гэрээгээ хийцгээе.</strong></el-row>
      <el-row class="mt10" type="flex" justify="center">
        <title-item title="Ерөнхий мэдээлэл"/>
      </el-row>
      <el-row :gutter="50" class="mt20">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="РЕГИСТРИЙН ДУГААР" prop="register_number">
            <el-input placeholder="Өмнөх бүртгэлтэй мэдээллийг автоматаар бөглөх засах боломжгүй" v-model="step2Form.register_number" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="ХАМТРАН АЖИЛЛАХ ТӨРӨЛ" prop="service">
            <el-select v-model="step2Form.service" placeholder="Үндсэн ангиллаас сонгоно" class="block" multiple>
              <el-option
                v-for="(service, indexService) in servicesList"
                :key="indexService"
                :label="service.name_mon"
                :value="service.name_eng">
                <template>
                  <el-tooltip class="item" effect="light" :content="service.name_eng === 'food' ? 'Түргэн хоол, Ресторан, Лоунж, Цайны газар үйлдвэрлэл үйлчилгээ хамааралтай.' : service.name_eng === 'grocery' ? 'Хүнсний бүх төрлийн бүтээгдэхүүн, хөлдөөсөн бүтээгдэхүүн үйлдвэрлэл үйлчилгээ хамааралтай.' : service.name_eng === 'cake' ? 'Кекс, дессерт, бялуу үйлдвэрлэл үйлчилгээ хамааралтай.' : service.name_eng === 'coffee bakery' ? 'Бүтээгдэхүүн: Bubble tea, цай, кофе үйлдвэрлэл үйлчилгээ хамааралтай.' : ''" placement="top-start">
                    <span>{{service.name_mon}}</span>
                  </el-tooltip>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="БАЙГУУЛЛАГЫН НЭР" prop="company_name">
            <el-input placeholder="Байгууллагын нэр" v-model="step2Form.company_name" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="САЛБАРЫН НЭР" prop="branch_name">
            <el-input placeholder="Рестораны нэр | Байршил Жишээ нь: Ток Ток | Хороолол" v-model="step2Form.branch_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50" class="mt30">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="БАНК" prop="bank">
            <el-select v-model="step2Form.bank" placeholder="Банкны мэдээлэл" class="block">
              <el-option
                v-for="(bank, indexBank) in bankCodes"
                :key="indexBank"
                :label="bank.bank_name_mon"
                :value="bank.bank_name_mon">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="ДАНСНЫ ДУГААР" prop="account_number">
            <el-input placeholder="Дансны дугаар" type="number" v-model="step2Form.account_number"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer-container mt20">
      <!-- <buttons :validate="validate"/> -->
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
      </el-row>
    </div>
  </div>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
import titleItem from '../../../../components/items/titleItem'
import Button from '../../../../components/items/button.vue'
// import Buttons from './buttons.vue'
export default {
  name: 'step2',
  components: {
    titleItem,
    Button
    // Buttons
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step2Form = this.data
    const registerData = JSON.parse(localStorage.getItem('registerData'))
    if (Object.prototype.hasOwnProperty.call(registerData, 'register')) {
      this.step2Form.register_number = registerData.register
      this.step2Form.company_name = registerData.company
    } else {
      this.$router.push({ path: '/login' })
    }
    this.getBankCodes()
    this.getServices()
  },
  data () {
    return {
      bankCodes: [],
      servicesList: [],
      step2Form: {
        service: [],
        register_number: '',
        register_name: '',
        bank: 'Хаан банк',
        branch_name: '',
        company_name: '',
        account_number: ''
      },
      rules: {
        service: [
          { type: 'array', required: true, message: 'Та хамтран ажиллах төрлөө сонгоно уу!', trigger: 'blur' }
        ],
        register_number: [
          { required: true, message: 'Та регистрийн дугаараа оруулна уу!', trigger: 'blur' }
        ],
        bank: [
          { required: true, message: 'Та банкаа сонгоно уу!', trigger: 'change' }
        ],
        account_number: [
          { required: true, message: 'Та дансны дугаараа оруулна уу!', trigger: 'blur' }
        ],
        branch_name: [
          { required: true, message: 'Та салбарын нэрээ стандартын дагуу оруулна уу!', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: 'Та байгууллагын нэрээ оруулна уу!', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    getBankCodes () {
      mAxios.get('getBankCodes').then(res => {
        if (res.data.status === 'success') {
          this.bankCodes = res.data.data
        }
      })
    },
    getServices () {
      mAxios.get('getServices').then(servicesResponse => {
        const tempData = []
        if (servicesResponse.data.status === 'success') {
          if (servicesResponse.data.data.length > 0) {
            servicesResponse.data.data.forEach(element => {
              if (element.name_eng !== 'discounted food' && element.name_eng !== 'delivery') {
                tempData.push(element)
              }
            })
          }
          this.servicesList = tempData
        }
      })
    },
    validate (type) {
      if (type === 'increase') {
        this.$refs.step2Form.validate((valid) => {
          if (valid) {
            this.onComplete('step3', type, this.step2Form)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.onComplete('step2', type, this.step2Form)
      }
    }
  }
}
</script>

<style>
.step .el-tag {
  font-size: 16px !important;
}

.step .el-select {
  display: flex;
  justify-content: center;
}
</style>
