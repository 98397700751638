<template>
  <div class="step">
    <el-form :model="step8Form" :rules="rules" ref="step8Form">
      <!-- <el-row class="mt10">
        <span class="gray-color">Гэрээ хийхэд шаардлагатай мэдээлэл</span>
      </el-row> -->
      <el-row type="flex" justify="center" class="m20">
        <title-item title="Гэрээ хийхэд шаардлагатай мэдээлэл" />
      </el-row>
      <el-row type="flex" justify="center">
        <span style="font-size: 12px; font-weight: 600">Хавсралтаа үнэн зөв оруулна уу</span>
      </el-row>
      <el-row :gutter="50" class="mt50" justify="center">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-row type="flex" justify="space-around" align="middle">
            <span class="header-button">1. ААН-ийн гэрчилгээний хуулбар</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <el-form-item prop="image1">
              <el-upload
                class="avatar-uploader"
                action=""
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="(file) => getFile(file, 'image1')">
                <el-row v-if="step8Form.image1">
                  <el-tag class="avatar" type="success">
                  <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                </el-row>
                <i v-else class="el-icon-picture-outline avatar-uploader-icon">
                </i>
              </el-upload>
              <loader :active="loading" />
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-row type="flex" justify="space-around" align="middle">
            <span class="header-button">2. НӨАТ төлөгчийн гэрчилгээний хуулбар</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <el-form-item prop="image2">
              <el-upload
                action=""
                class="avatar-uploader"
                :show-file-list="false"
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
                :auto-upload="false"
                :on-change="(file) => getFile(file, 'image2')">
                <!-- <img v-if="step8Form.image2" :src="step8Form.image2" class="avatar"> -->
                <el-row v-if="step8Form.image2">
                  <el-tag class="avatar" type="success">
                  <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                </el-row>
                <i v-else class="el-icon-picture-outline avatar-uploader-icon"></i>
              </el-upload>
              <loader :active="loading" />
            </el-form-item>
          </el-row>
        </el-col>
        <!-- <el-col :span="8" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
          <el-row type="flex" justify="space-around" align="middle">
            <span class="header-button">3. Иргэний үнэмлэхийн хуулбар</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <el-form-item prop="image3">
              <el-upload
                action=""
                class="avatar-uploader"
                :show-file-list="false"
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
                :auto-upload="false"
                :on-change="(file) => getFile(file, 'image3')">
                <el-row v-if="step8Form.image3">
                  <el-tag class="avatar" type="success">
                  <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                </el-row>
                <i v-else class="el-icon-picture-outline avatar-uploader-icon"></i>
              </el-upload>
              <loader :active="loading" />
            </el-form-item>
          </el-row>
        </el-col> -->
      </el-row>
      <el-row :gutter="50" justify="center">
        <!-- <el-col :span="8" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
          <el-row type="flex" justify="space-around" align="middle">
            <span class="header-button">4. Тусгай зөвшөөрлийн хуулбар</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <el-form-item prop="image4">
              <el-upload
                action=""
                class="avatar-uploader"
                :show-file-list="false"
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
                :auto-upload="false"
                :on-change="(file) => getFile(file, 'image4')">
                <el-row v-if="step8Form.image4">
                  <el-tag class="avatar" type="success">
                  <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                </el-row>
                <i v-else class="el-icon-picture-outline avatar-uploader-icon"></i>
              </el-upload>
              <loader :active="loading" />
            </el-form-item>
          </el-row>
        </el-col> -->
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-row type="flex" justify="space-around" align="middle">
            <span class="header-button">5. Үйлчилгээ эрхлэх батламж</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <el-form-item prop="image5">
              <el-upload
                action=""
                class="avatar-uploader"
                :show-file-list="false"
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
                :auto-upload="false"
                :on-change="(file) => getFile(file, 'image5')">
                <!-- <img v-if="step8Form.image5" :src="step8Form.image5" class="avatar"> -->
                <el-row v-if="step8Form.image5">
                  <el-tag class="avatar" type="success">
                  <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                </el-row>
                <i v-else class="el-icon-picture-outline avatar-uploader-icon"></i>
              </el-upload>
              <loader :active="loading" />
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-row type="flex" justify="space-around" align="middle">
            <span class="header-button">6. Түрээсийн гэрээний хуулбар</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <el-form-item prop="image6">
              <el-upload
                action=""
                class="avatar-uploader"
                :show-file-list="false"
                accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
                :auto-upload="false"
                :on-change="(file) => getFile(file, 'image6')">
                <!-- <img v-if="step8Form.image6" :src="step8Form.image6" class="avatar"> -->
                <el-row v-if="step8Form.image6">
                  <el-tag class="avatar" type="success">
                  <i class="el-icon-check mr5" />Амжилттай хуулагдлаа.</el-tag>
                </el-row>
                <i v-else class="el-icon-picture-outline avatar-uploader-icon"></i>
              </el-upload>
              <loader :active="loading" />
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <!-- <div class="footer-container mt80">
      <buttons :validate="validate" :title="'ҮРГЭЛЖЛҮҮЛЭХ'"/>
    </div> -->
    <div class="footer-container mt20">
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
      </el-row>
      <el-row class="mt10 tt-button-text">
        <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
// import Buttons from './buttons.vue'
import { getBase64 } from '../../../../helper/custom'
// import mAxios from '../../../../helper/axiosInstance'
import Loader from '../../../../components/loader.vue'
import Button from '../../../../components/items/button'
import titleItem from '../../../../components/items/titleItem'
export default {
  name: 'step8',
  components: {
    // Buttons,
    Loader,
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step8Form = this.data
  },
  data () {
    return {
      loading: false,
      step8Form: {
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        image5: '',
        image6: ''
      },
      rules: {
        image1: [
          { required: true, message: 'Хуулбараа оруулна уу!', trigger: 'blur' }
        ],
        image2: [
          { required: true, message: 'Хуулбараа оруулна уу!', trigger: 'blur' }
        ],
        image3: [
          { required: true, message: 'Хуулбараа оруулна уу!', trigger: 'blur' }
        ],
        image4: [
          { required: true, message: 'Хуулбараа оруулна уу!', trigger: 'blur' }
        ],
        image5: [
          { required: true, message: 'Батламжаа оруулна уу!', trigger: 'blur' }
        ],
        image6: [
          { required: true, message: 'Хуулбараа оруулна уу!', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async getFile (file, type) {
      this.loading = true
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        this.$message.error('Хуулах файлын хэмжээ 1MB-с хэтрэхгүй байх ёстой!')
      }
      if (isLt2M) {
        const tempType = file.raw.type.split('/')
        await getBase64(file.raw).then(response => {
          const payload = {}
          payload.base64 = response
          // mAxios.post('other/uploadImage', payload).then((res) => {
          if (type === 'image1') {
            this.step8Form.image1 = response
            this.$store.dispatch('setStepData', { step: 'image1', response, type: tempType[1], contentType: tempType[0] }).then(() => {
              this.$emit('change')
            })
          } else if (type === 'image2') {
            this.step8Form.image2 = response
            this.$store.dispatch('setStepData', { step: 'image2', response, type: tempType[1], contentType: tempType[0] }).then(() => {
              this.$emit('change')
            })
          } else if (type === 'image3') {
            this.step8Form.image3 = response
            this.$store.dispatch('setStepData', { step: 'image3', response, type: tempType[1], contentType: tempType[0] }).then(() => {
              this.$emit('change')
            })
          } else if (type === 'image4') {
            this.step8Form.image4 = response
            this.$store.dispatch('setStepData', { step: 'image4', response, type: tempType[1], contentType: tempType[0] }).then(() => {
              this.$emit('change')
            })
          } else if (type === 'image5') {
            this.step8Form.image5 = response
            this.$store.dispatch('setStepData', { step: 'image5', response, type: tempType[1], contentType: tempType[0] }).then(() => {
              this.$emit('change')
            })
          } else {
            this.step8Form.image6 = response
            this.$store.dispatch('setStepData', { step: 'image6', response, type: tempType[1], contentType: tempType[0] }).then(() => {
              this.$emit('change')
            })
          }
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    validate (type) {
      if (type === 'increase') {
        this.$refs.step8Form.validate((valid) => {
          if (valid) {
            this.onComplete('step9', type, this.step8Form)
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.onComplete('step9', type, this.step8Form)
      }
    }
  }
}
</script>

<style scoped>
.el-tag.el-tag--success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.header-button {
  font-size: 12px;
}
.avatar-uploader .el-upload {
    border: 1px solid #E6E6E6;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: orange;
  }
  .avatar-uploader-icon {
    border: 1px solid #E6E6E6;
    border-radius: 30px;
    font-size: 28px;
    color: #E6E6E6;
    width: 338px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 338px;
    height: 178px;
    border-radius: 30px;
    display: block;
  }
  .example {
    font-size: 10px; color: #3A3A3C; background-color: #fff; border-radius: 30px; outline: none; cursor: pointer; padding: 5px 10px 5px 10px; border: none
  }
</style>
