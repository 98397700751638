<template>
  <el-aside :class="isSun ? 'main-sidebar' : 'main-sidebar2'">
    <ul class="main-menu">
      <li :class="(($route.name === 'home') ? 'activeSidebar': '')">
        <router-link :to="{ name: 'home'}">
          <home-icon class="icon" :size="24" />
          <span>Тайлан</span>
        </router-link>
      </li>
      <li :class="((['settlement'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{ name: 'settlement'}">
          <settlement-icon class="icon" :size="20" />
          <span>Тооцоо нийлэх</span>
        </router-link>
        <ul>
          <li>
            <router-link to="/settlement">Төлбөр тооцоо</router-link>
          </li>
          <li>
            <router-link to="/settlementAct">Тооцоо нийлсэн акт</router-link>
          </li>
        </ul>
      </li>
      <li :class="((['info'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{ name: 'info' }">
          <info-icon class="icon" :size="20" />
          <span>Харилцагчийн мэдээлэл</span>
        </router-link>
      </li>
      <li :class="((['timetable'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{ name: 'timetable' }">
          <time-table-icon class="icon" :size="20" />
          <span>Цагийн хуваарь</span>
        </router-link>
      </li>
      <li :class="((['products'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{name: 'products'}">
          <menu-icon class="icon" :size="20" />
          <span>Бүтээгдэхүүн</span>
        </router-link>
      </li>
      <li :class="((['marketing'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{name: 'marketing'}">
          <atom-icon class="icon" :size="20" />
          <span>Маркетинг</span>
        </router-link>
      </li>
      <li :class="((['deliveryZone'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{ name: 'deliveryZone' }">
          <map-icon class="icon" :size="20" />
          <span>Хүргэлтийн бүс</span>
        </router-link>
      </li>
      <li :class="((['complain'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{ name: 'complain' }">
          <complain-icon class="icon" :size="20" />
          <span>Санал гомдол</span>
        </router-link>
      </li>
      <li :class="((['phone'].includes($route.name )) ? 'activeSidebar': '')">
        <router-link :to="{ name: 'phone' }">
          <phone-icon class="icon" :size="20" />
          <span>Холбогдох утас</span>
        </router-link>
      </li>
    </ul>
    <el-row align="center" class="ml20 mt10">
      <el-col class="text-left">
        <i :class="isSun ? 'el-icon-moon mr5 iconSun' : 'el-icon-moon mr5 iconDark'">
        </i>
        <el-switch v-model="isSun"></el-switch>
        <i :class="isSun ? 'el-icon-sunny ml5 iconSun' : 'el-icon-sunny ml5 iconDark'">
        </i>
      </el-col>
    </el-row>
  </el-aside>
</template>
<script>
import MenuIcon from 'vue-material-design-icons/Tag.vue'
import HomeIcon from 'vue-material-design-icons/TrendingUp'
import InfoIcon from 'vue-material-design-icons/AccountBox'
import MapIcon from 'vue-material-design-icons/Map'
import TimeTableIcon from 'vue-material-design-icons/Clock'
import SettlementIcon from 'vue-material-design-icons/CashCheck'
import ComplainIcon from 'vue-material-design-icons/ThumbUp.vue'
import PhoneIcon from 'vue-material-design-icons/Phone'
import AtomIcon from 'vue-material-design-icons/Atom'
export default {
  components: {
    HomeIcon,
    MenuIcon,
    InfoIcon,
    MapIcon,
    TimeTableIcon,
    SettlementIcon,
    ComplainIcon,
    PhoneIcon,
    AtomIcon
  },
  data () {
    return {
      route: '',
      isSun: true
    }
  }
}
</script>
<style scoped>
.iconDark {
    color: white;
    font-size: 1rem;
}
.iconSun {
  color: rgb(48, 65, 86);
  font-size: 1rem;
}
</style>
