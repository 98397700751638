<template>
  <div class="step">
    <el-form :model="step4Form" :rules="rules" ref="step4Form">
      <el-row type="flex" justify="center" class="m20">
        <!-- <span>Салбарын хаяг</span> -->
        <title-item title="Салбарын хаяг" />
      </el-row>
      <el-form-item prop="address_id">
        <el-select
          v-model="step4Form.address_id"
          class="block"
          filterable
          placeholder="ХУД 3-р хороо Гоёо"
          remote
          reserve-keyword
          :remote-method="searchAddress"
          @change="changeAddress(step4Form.address_id)"
        >
          <el-option
            class="mobileWdith"
            v-for="(address, index) in addressList"
            :label="address.full_address"
            :value="address._id"
            :key="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div>
        <div class="google-map mt20" style="height:320px;" v-loading.fullscreen.lock="loading">
          <gmap-map
            :center="step4Form.location"
            :zoom="12"
            :options="mapOptions"
            style="width: 100%; height: 100%; margin-top: 5px"
          >
            <gmap-marker
              :position="step4Form.location"
              :clickable="true"
              @dragend="changeLoc($event)"
              :draggable="true"
            />
          </gmap-map>
          <el-row type="flex" justify="center">
            <span style="font-size: 14px; color: red">Таны оруулсан хаягийн байршлаас шалтгаалан хүргэлтийн бүс нь газрын зургийн хар саарал өнгөөр ялгарч харагдаж байна.</span>
          </el-row>
          <el-row type="flex" justify="center" class="mt10">
            <span style="font-size: 18px"><strong>Та газрын зургыг хулганы голын товчлуурыг ашиглан томруулан салбарынхаа байршлыг тэмдэглээрэй.</strong></span>
          </el-row>
          <div class="footer-container mt20">
            <!-- <buttons :validate="validate"/> -->
            <el-row type="flex" justify="center">
              <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
            </el-row>
            <el-row class="mt10 tt-button-text">
              <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
            </el-row>
          </div>
          <!-- <div class="footer-container mt80">
            <buttons :validate="validate" :title="'ҮРГЭЛЖЛҮҮЛЭХ'"/>
          </div> -->
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
// import Buttons from './buttons.vue'
import Button from '../../../../components/items/button'
import axios from 'axios'
import titleItem from '../../../../components/items/titleItem'
export default {
  name: 'step4',
  components: {
    // Buttons,
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step4Form = this.data
    this.nearbyAddress()
  },
  data () {
    return {
      loading: false,
      addressList: [],
      step4Form: {
        address_id: '',
        full_address: '',
        location: {
          lat: 47.918831,
          lng: 106.917558
        }
      },
      full_address: '',
      location: {
        lat: 47.918831,
        lng: 106.917558
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: 'greedy'
      },
      rules: {
        address_id: [
          { required: true, message: 'Та хаягаа заавал оруулна уу!', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async searchAddress (address) {
      this.loading = true
      await axios.get(`https://api.toktok.mn/v1/address/search?address=${address}`).then(address => {
        this.addressList = address.data.data
      })
      this.loading = false
    },
    validate (type) {
      if (type === 'increase') {
        this.$refs.step4Form.validate((valid) => {
          if (valid) {
            this.onComplete('step5', type, this.step4Form)
          } else {
            return false
          }
        })
      } else {
        this.onComplete('step3', type, this.step4Form)
      }
    },

    changeLoc (event) {
      this.loading = true
      this.step4Form.location.lat = event.latLng.lat()
      this.step4Form.location.lng = event.latLng.lng()
      this.loading = false
      this.nearbyAddress()
    },

    async nearbyAddress () {
      this.loading = true
      await axios.get('https://api.toktok.mn/v1/address/getAddressNearby?lat=' + this.step4Form.location.lat + '&lng=' + this.step4Form.location.lng).then(address => {
        this.addressList = address.data.data
      })
      this.step4Form.address_id = this.addressList[0]._id
      this.step4Form.full_address = this.addressList[0].full_address
      this.loading = false
    },
    changeAddress (address) {
      this.addressList.forEach(res => {
        this.loading = true
        if (res._id === address) {
          this.step4Form.address_id = res._id
          this.step4Form.full_address = res.full_address
          this.step4Form.location.lat = parseFloat(res.lat)
          this.step4Form.location.lng = parseFloat(res.lon)
        }
      })
      this.loading = false
    }
  }
}
</script>

<style>
  @media screen and (max-width: 800px) {
.mobileWdith {
      max-width: 320px !important;
      left: 0px !important;
  }
}
</style>
