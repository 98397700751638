<template>
  <div>
    <login-header :percentage="percentage"/>
    <!-- <header-partner /> -->
    <div  class="user-form-container">
      <div>
        <!-- <step-1 v-if="step === 'step1'" :onComplete="onComplete" :data="step1"/> -->
        <step-2 v-if="step === 'step2'" :onComplete="onComplete" :data="step2"/>
        <step-3 v-else-if="step === 'step3'" :onComplete="onComplete" :data="step3"/>
        <step-4 v-else-if="step === 'step4'" :onComplete="onComplete" :data="step4"/>
        <step-5 v-else-if="step === 'step5'" :onComplete="onComplete" :data="step5"/>
        <step-6 v-else-if="step === 'step6'" :onComplete="onComplete" :data="step6"/>
        <step-7 v-else-if="step === 'step7'" :onComplete="onComplete" :data="step7"/>
        <step-8 v-else-if="step === 'step8'" :onComplete="onComplete" :data="step8"/>
        <step-9 v-else-if="step === 'step9'" :onComplete="onComplete" :register="register" :loading="loading" :download-loading="downloadLoading"/>
        <!-- <step-10 v-else :onComplete="onComplete" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import mAxios from '../../../helper/axiosInstance'
import { signOut } from '../../../helper/auth'
import { Auth } from 'aws-amplify'
import loginHeader from '../../../components/items/loginHeader'
// import step1 from './components/step1.vue'
import step2 from './components/step2.vue'
import step3 from './components/step3.vue'
import step4 from './components/step4.vue'
import step5 from './components/step5.vue'
import step6 from './components/step6.vue'
import step7 from './components/step7.vue'
import step8 from './components/step8.vue'
import step9 from './components/step9.vue'
// import step10 from './components/step10.vue'
// import userProgress from './components/progress.vue'
export default {
  name: 'createPartner',
  components: {
    // step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    // step10,
    // Buttons,
    // userProgress,
    loginHeader
  },
  async created () {
    this.user = await Auth.currentAuthenticatedUser()
    this.storageClientData = JSON.parse(localStorage.getItem('clientData'))
    this.generateSteps()
  },
  data () {
    return {
      downloadLoading: false,
      loading: false,
      user: null,
      storageClientData: null,
      stepsData: null,
      step: 'step2',
      percentage: 0,
      step1: {
        position: '',
        name: '',
        phone: '',
        email: ''
      },
      step2: {
        service: [],
        register_number: '',
        register_name: '',
        bank: 'Хаан банк',
        branch_name: '',
        account_number: '',
        company_name: ''
      },
      step3: {
        mon: {
          startTime: '',
          endTime: ''
        },
        tue: {
          startTime: '',
          endTime: ''
        },
        wed: {
          startTime: '',
          endTime: ''
        },
        thu: {
          startTime: '',
          endTime: ''
        },
        fri: {
          startTime: '',
          endTime: ''
        },
        sun: {
          startTime: '',
          endTime: ''
        },
        sat: {
          startTime: '',
          endTime: ''
        }
      },
      step4: {
        address_id: '',
        full_address: '',
        location: {
          lat: 47.918831,
          lng: 106.917558
        }
      },
      step5: {
        ceo_email: '',
        ceo_phone: '',
        manager_email: '',
        manager_phone: '',
        finance_email: '',
        finance_phone: '',
        order_email: '',
        order_phone: ''
      },
      step6: {
        isPercent: true,
        toktok: true,
        supplier: false,
        toktok_percent: '20',
        toktok_amount: '3000',
        supplier_percent: '5',
        supplier_amount: '3000'
      },
      step7: {
        additional_name: '',
        additional_register: '',
        additional_last_name: '',
        additional_first_name: '',
        representative_name: '',
        representative_position: ''
      },
      step8: {
        image1: '',
        image2: '',
        image3: '',
        image4: '',
        image5: '',
        image6: ''
      },
      step9: {
        step: ''
      }
    }
  },
  methods: {
    generatePayload () {
      this.stepsData = JSON.parse(localStorage.getItem('vuex'))
      const payload = {
        is_child: false,
        email: this.storageClientData.email,
        full_name_eng: this.step2.branch_name,
        service_list: this.step2.service,
        username: this.user.username,
        time_table: this.step3,
        phone_list: [
          {
            phone: this.step5.order_phone,
            name: 'Захиалга',
            email: this.step5.order_email
          },
          {
            phone: this.step5.manager_phone,
            name: 'Менежер',
            email: this.step5.manager_email
          },
          {
            phone: this.step5.finance_phone,
            name: 'Санхүү',
            email: this.step5.finance_email
          },
          {
            phone: this.step5.ceo_phone,
            name: 'Захирал',
            email: this.step5.ceo_email
          }
        ],
        address: this.step4.full_address,
        location: this.step4.location.lat + ', ' + this.step4.location.lng,
        vat_regno: this.step2.register_number,
        vat_type: this.step2.register_number.length === 10 ? 'individual' : 'company',
        company_name: this.step2.company_name,
        cognito_id: this.storageClientData.sub,
        bank_account_name: this.step2.bank,
        bank_account_number: this.step2.account_number,
        is_delivery_by_outlet: this.step6.supplier,
        commission_type: this.step6.toktok === true ? 'percent' : this.step6.supplier_percent !== '5' ? 'percent' : 'fixed',
        percent: this.step6.toktok === true ? this.step6.toktok_percent : this.step6.supplier === true && this.step6.supplier_percent !== '5' ? this.step6.supplier_percent : this.step6.supplier_amount,
        contract_owner_position: this.step7.representative_position,
        contract_owner: this.step7.representative_name,
        director_first_name: this.step7.additional_first_name,
        director_last_name: this.step7.additional_last_name,
        files: [
          {
            file: this.step8.image1,
            name: 'AAN',
            type: this.stepsData.registrationStep.steps.fileType.image1.type,
            contentType: this.stepsData.registrationStep.steps.fileType.image1.contentType
          },
          {
            file: this.step8.image2,
            name: 'NOAT',
            type: this.stepsData.registrationStep.steps.fileType.image2.type,
            contentType: this.stepsData.registrationStep.steps.fileType.image2.contentType
          },
          {
            file: this.step8.image3,
            name: 'IDCARD',
            type: this.stepsData.registrationStep.steps.fileType.image3.type,
            contentType: this.stepsData.registrationStep.steps.fileType.image3.contentType
          },
          {
            file: this.step8.image4,
            name: 'LICENSE_COPY',
            type: this.stepsData.registrationStep.steps.fileType.image4.type,
            contentType: this.stepsData.registrationStep.steps.fileType.image4.contentType
          },
          {
            file: this.step8.image5,
            name: 'CERT_OF_SERVICE',
            type: this.stepsData.registrationStep.steps.fileType.image5.type,
            contentType: this.stepsData.registrationStep.steps.fileType.image5.contentType
          },
          {
            file: this.step8.image6,
            name: 'RENT_COPY',
            type: this.stepsData.registrationStep.steps.fileType.image6.type,
            contentType: this.stepsData.registrationStep.steps.fileType.image6.contentType
          }
        ]
      }
      return payload
    },

    async register () {
      const payload = this.generatePayload()
      if (!['', undefined, null].includes(payload.time_table.mon.endTime) && !['', undefined, null].includes(payload.time_table.mon.startTime)) {
        if (!['', undefined, null].includes(payload.time_table.tue.endTime) && !['', undefined, null].includes(payload.time_table.tue.startTime)) {
          if (!['', undefined, null].includes(payload.time_table.wed.endTime) && !['', undefined, null].includes(payload.time_table.wed.startTime)) {
            if (!['', undefined, null].includes(payload.time_table.thu.endTime) && !['', undefined, null].includes(payload.time_table.thu.startTime)) {
              if (!['', undefined, null].includes(payload.time_table.fri.endTime) && !['', undefined, null].includes(payload.time_table.fri.startTime)) {
                if (!['', undefined, null].includes(payload.time_table.sat.endTime) && !['', undefined, null].includes(payload.time_table.sat.startTime)) {
                  if (!['', undefined, null].includes(payload.time_table.sun.endTime) && !['', undefined, null].includes(payload.time_table.sun.startTime)) {
                    this.loading = true
                    await mAxios.post('outlet/registerPartner', payload).then(async (response) => {
                      if (response.data.status === 'success') {
                        this.$alert('Хамтран ажиллах хүсэлт амжилттай бүртгэгдлээ. Тань руу ажлын 3 хоногт багтаан холбогдох болно.', 'Амжилттай', {
                          customClass: 'customClassBody',
                          type: 'success',
                          center: true,
                          confirmButtonText: 'Ойлголоо'
                        })
                        signOut()
                        localStorage.removeItem('vuex')
                      } else {
                        this.loading = false
                        this.$message.error({
                          title: 'Алдаа гарлаа',
                          message: response.data.message,
                          type: 'error'
                        })
                      }
                      this.loading = false
                    })
                  } else {
                    this.step = 'step3'
                    this.$notify.error({
                      title: 'Алдаа',
                      message: 'Цагийн хуваарь дутуу байна'
                    })
                  }
                } else {
                  this.step = 'step3'
                  this.$notify.error({
                    title: 'Алдаа',
                    message: 'Цагийн хуваарь дутуу байна'
                  })
                }
              } else {
                this.step = 'step3'
                this.$notify.error({
                  title: 'Алдаа',
                  message: 'Цагийн хуваарь дутуу байна'
                })
              }
            } else {
              this.step = 'step3'
              this.$notify.error({
                title: 'Алдаа',
                message: 'Цагийн хуваарь дутуу байна'
              })
            }
          } else {
            this.step = 'step3'
            this.$notify.error({
              title: 'Алдаа',
              message: 'Цагийн хуваарь дутуу байна'
            })
          }
        } else {
          this.step = 'step3'
          this.$notify.error({
            title: 'Алдаа',
            message: 'Цагийн хуваарь дутуу байна'
          })
        }
      } else {
        this.step = 'step3'
        this.$notify.error({
          title: 'Алдаа',
          message: 'Цагийн хуваарь дутуу байна'
        })
      }
      setTimeout(() => {
        this.loading = false
      }, 2000)
      // this.loading = false
    },

    generateSteps () {
      this.stepsData = JSON.parse(localStorage.getItem('vuex'))
      if (this.stepsData !== null) {
        if (this.stepsData.registrationStep.steps.step2 !== null && this.stepsData.registrationStep.steps.step3 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step = 'step3'
          this.percentage = 14.5
        } else if (this.stepsData.registrationStep.steps.step3 !== null && this.stepsData.registrationStep.steps.step4 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step = 'step4'
          this.percentage = 29
        } else if (this.stepsData.registrationStep.steps.step4 !== null && this.stepsData.registrationStep.steps.step5 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step4 = this.stepsData.registrationStep.steps.step4
          this.step = 'step5'
          this.percentage = 43.5
        } else if (this.stepsData.registrationStep.steps.step5 !== null && this.stepsData.registrationStep.steps.step6 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step4 = this.stepsData.registrationStep.steps.step4
          this.step5 = this.stepsData.registrationStep.steps.step5
          this.step = 'step6'
          this.percentage = 57
        } else if (this.stepsData.registrationStep.steps.step6 !== null && this.stepsData.registrationStep.steps.step7 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step4 = this.stepsData.registrationStep.steps.step4
          this.step5 = this.stepsData.registrationStep.steps.step5
          this.step6 = this.stepsData.registrationStep.steps.step6
          this.step = 'step7'
          this.percentage = 71.5
        } else if (this.stepsData.registrationStep.steps.step7 !== null && this.stepsData.registrationStep.steps.step8 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step4 = this.stepsData.registrationStep.steps.step4
          this.step5 = this.stepsData.registrationStep.steps.step5
          this.step6 = this.stepsData.registrationStep.steps.step6
          this.step7 = this.stepsData.registrationStep.steps.step7
          this.step = 'step8'
          this.percentage = 75
        } else if (this.stepsData.registrationStep.steps.step8 !== null && this.stepsData.registrationStep.steps.step9 === null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step4 = this.stepsData.registrationStep.steps.step4
          this.step5 = this.stepsData.registrationStep.steps.step5
          this.step6 = this.stepsData.registrationStep.steps.step6
          this.step7 = this.stepsData.registrationStep.steps.step7
          this.step8 = this.stepsData.registrationStep.steps.step8
          this.step = 'step9'
          this.percentage = 100
        } else if (this.stepsData.registrationStep.steps.step9 !== null) {
          // this.step1 = this.stepsData.registrationStep.steps.step1
          this.step2 = this.stepsData.registrationStep.steps.step2
          this.step3 = this.stepsData.registrationStep.steps.step3
          this.step4 = this.stepsData.registrationStep.steps.step4
          this.step5 = this.stepsData.registrationStep.steps.step5
          this.step6 = this.stepsData.registrationStep.steps.step6
          this.step7 = this.stepsData.registrationStep.steps.step7
          this.step8 = this.stepsData.registrationStep.steps.step8
          this.step9 = this.stepsData.registrationStep.steps.step9
          this.step = 'step10'
          this.percentage = 100
        }
      }
    },

    onComplete (step, type, data) {
      if (step === 'step2' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'name')) {
        // Үргэлжлүүлэх step1
        this.$store.dispatch('setStepData', { step: 'step1', data }).then(() => {
          this.$emit('change')
        })
        this.step1 = data
        this.step = step
        this.increase()
      } else if (step === 'step3' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'account_number')) {
        // Үргэлжлүүлэх step2
        this.$store.dispatch('setStepData', { step: 'step2', data }).then(() => {
          this.$emit('change')
        })
        this.step2 = data
        this.step = step
        this.increase()
      } else if (step === 'step2' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'account_number')) {
        // Буцах step2
        this.step = 'step1'
        this.decrease()
      } else if (step === 'step4' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'fri')) {
        // Үргэлжлүүлэх step3
        this.$store.dispatch('setStepData', { step: 'step3', data }).then(() => {
          this.$emit('change')
        })
        this.step3 = data
        this.step = step
        this.increase()
      } else if (step === 'step2' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'fri')) {
        // Буцах step2
        this.step = 'step2'
        this.decrease()
      } else if (step === 'step5' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'address_id')) {
        // Үргэлжлүүлэх step4
        this.$store.dispatch('setStepData', { step: 'step4', data }).then(() => {
          this.$emit('change')
        })
        this.step4 = data
        this.step = step
        this.increase()
      } else if (step === 'step3' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'address_id')) {
        // Буцах step4
        this.step = 'step3'
        this.decrease()
      } else if (step === 'step6' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'ceo_email')) {
        // Үргэлжлүүлэх step5
        this.$store.dispatch('setStepData', { step: 'step5', data }).then(() => {
          this.$emit('change')
        })
        this.step5 = data
        this.step = step
        this.increase()
      } else if (step === 'step4' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'ceo_email')) {
        // Буцах step5
        this.step = 'step4'
        this.decrease()
      } else if (step === 'step7' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'toktok')) {
        // Үргэлжлүүлэх step6
        this.$store.dispatch('setStepData', { step: 'step6', data }).then(() => {
          this.$emit('change')
        })
        this.step6 = data
        this.step = step
        this.increase()
      } else if (step === 'step5' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'toktok')) {
        // Буцах step6
        this.step = 'step5'
        this.decrease()
      } else if (step === 'step8' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'representative_position')) {
        // Үргэлжлүүлэх step7
        this.$store.dispatch('setStepData', { step: 'step7', data }).then(() => {
          this.$emit('change')
        })
        this.step7 = data
        this.step = step
        this.increase()
      } else if (step === 'step6' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'representative_position')) {
        // Буцах step7
        this.step = 'step6'
        this.decrease()
      } else if (step === 'step9' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'image1')) {
        // Үргэлжлүүлэх step8
        this.$store.dispatch('setStepData', { step: 'step8', data }).then(() => {
          this.$emit('change')
        })
        this.step8 = data
        this.step = step
        this.increase()
      } else if (step === 'step9' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'image1')) {
        // Буцах step8
        this.step = 'step7'
        this.decrease()
      } else if (step === 'step10' && type === 'increase' && Object.prototype.hasOwnProperty.call(data, 'step9')) {
        // Үргэлжлүүлэх step9
        this.$store.dispatch('setStepData', { step: 'step9', data }).then(() => {
          this.$emit('change')
        })
        this.step = step
        this.increase()
      } else if (step === 'step8' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'step9')) {
        // Буцах step9
        this.step = 'step8'
        this.decrease()
      } else if (step === 'step9' && type === 'decrease' && Object.prototype.hasOwnProperty.call(data, 'step10')) {
        // Буцах step10
        this.step = 'step9'
        this.decrease()
      }
    },

    increase () {
      this.percentage += 14.5
      if (this.percentage > 100) {
        this.percentage = 100
      }
    },

    decrease () {
      this.percentage -= 14.5
      if (this.percentage < 0) {
        this.percentage = 0
      }
      if (this.percentage === 4) {
        this.percentage = 0
      }
    }
  }
}
</script>

<style>
.customClassBody {
  width: 330px;
}
</style>
