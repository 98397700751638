const registrationStep = {
  state: {
    steps: {
      step1: null,
      step2: null,
      step3: null,
      step4: null,
      step5: null,
      step6: null,
      step7: null,
      step8: null,
      step9: null,
      files: {
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null
      },
      fileType: {
        image1: {
          type: '',
          contentType: ''
        },
        image2: {
          type: '',
          contentType: ''
        },
        image3: {
          type: '',
          contentType: ''
        },
        image4: {
          type: '',
          contentType: ''
        },
        image5: {
          type: '',
          contentType: ''
        },
        image6: {
          type: '',
          contentType: ''
        }
      }
    }
  },
  getters: {
    steps: state => state.steps
  },
  mutations: {
    SET_STEP_DATA: (state, stepData) => {
      if (stepData.step.includes('step')) {
        state.steps[stepData.step] = stepData.data
      } else {
        state.steps.files[stepData.step] = stepData.response
        state.steps.fileType[stepData.step].type = stepData.type
        state.steps.fileType[stepData.step].contentType = stepData.contentType
      }
    }
  },
  actions: {
    setStepData ({ commit }, stepData) {
      return new Promise((resolve, reject) => {
        commit('SET_STEP_DATA', stepData)
      })
    }

  }
}

export default registrationStep
