<template>
  <div class="bg-baseWhite md:hidden">
    <div class="flex flex-row px-2.5 py-2.5 justify-between border-b-1 border-b-solid border-baseGray">
      <div class="flex flex-row gap-2.5 justify-center items-center">
        <img :src="displayImage" alt="" style="height: 49px; width: 49px">
        <span class=" text-base font-semibold text-baseBlack">{{!$root.isAdmin ? displayName : ''}}</span>
      </div>
      <div class="flex flex-row justify-center items-center p-15 rounded-xl bg-baseBackgroundGray cursor-pointer" @click="onPressToggleMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none">
          <path d="M17.8125 6.3125H1.1875C0.531666 6.3125 0 6.84417 0 7.5C0 8.15583 0.531666 8.6875 1.1875 8.6875H17.8125C18.4683 8.6875 19 8.15583 19 7.5C19 6.84417 18.4683 6.3125 17.8125 6.3125Z" fill="#3A3A3C"/>
          <path d="M1.1875 3.14588H17.8125C18.4683 3.14588 19 2.61421 19 1.95838C19 1.30254 18.4683 0.770874 17.8125 0.770874H1.1875C0.531666 0.770874 0 1.30254 0 1.95838C0 2.61421 0.531666 3.14588 1.1875 3.14588Z" fill="#3A3A3C"/>
          <path d="M17.8125 11.8541H1.1875C0.531666 11.8541 0 12.3858 0 13.0416C0 13.6975 0.531666 14.2291 1.1875 14.2291H17.8125C18.4683 14.2291 19 13.6975 19 13.0416C19 12.3858 18.4683 11.8541 17.8125 11.8541Z" fill="#3A3A3C"/>
        </svg>
      </div>
    </div>
    <div>
      <el-menu class="el-menu-vertical-demo" :hidden="isCollapse" :collapse="isCollapse" style="min-height: 100vh; width: 80vw; z-index: 15;">
        <el-menu-item @click="onPressToggleMenu" index="1" :class="(($route.name === 'home') ? 'active': '')">
          <router-link :to="{ name: 'home' }">
            <home-icon class="icon" :size="24" />
            <span class="text" :style="((['home'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Тайлан</span>
          </router-link>
        </el-menu-item>
        <el-submenu @click="onPressToggleMenu" index="2">
          <template slot="title">
            <settlement-icon class="icon" :class="(($route.name === 'settlement') ? 'active': '')" :size="20" />
            <router-link :to="{ name: 'settlement'}" slot="title" :style="((['settlement'].includes($route.name )) ? 'color: rgb(36, 204, 120); margin-left: 10px;': 'margin-left: 10px;')">Тооцоо нийлэх</router-link>
          </template>
          <el-menu-item @click="onPressToggleMenu" index="2-1" :class="((['settlement'].includes($route.name )) ? 'active': '')">
            <router-link to="/settlement">Төлбөр тооцоо</router-link>
          </el-menu-item>
          <el-menu-item @click="onPressToggleMenu" index="2-2" :class="((['settlementAct'].includes($route.name )) ? 'active': '')">
            <router-link to="/settlementAct">Тооцоо нийлсэн акт</router-link>
          </el-menu-item>
        </el-submenu>
        <el-menu-item @click="onPressToggleMenu" index="3" :class="((['info'].includes($route.name )) ? 'active': '')">
          <router-link :to="{ name: 'info' }">
            <info-icon class="icon" :size="20" />
            <span class="text" :style="((['info'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Харилцагчийн мэдээлэл</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="4" :class="((['timetable'].includes($route.name )) ? 'active': '')">
          <router-link :to="{ name: 'timetable' }">
            <time-table-icon class="icon" :size="20" />
            <span class="text" :style="((['timetable'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Цагийн хуваарь</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="5">
          <router-link :to="{ name: 'marketing' }">
            <atom-icon class="icon" :size="20" />
            <span class="text" :style="((['marketing'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Маркетинг</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="6" :class="((['products'].includes($route.name )) ? 'active': '')">
          <router-link :to="{name: 'outlet'}">
            <menu-icon class="icon" :size="20" />
            <span class="text" :style="((['outlet'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Бүтээгдэхүүн</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="7" :class="((['deliveryZone'].includes($route.name )) ? 'active': '')">
          <router-link :to="{ name: 'deliveryZone' }">
            <map-icon class="icon" :size="20" />
            <span class="text" :style="((['deliveryZone'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Хүргэлтийн бүс</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="8" :class="((['complain'].includes($route.name )) ? 'active': '')">
          <router-link :to="{ name: 'complain' }">
            <complain-icon class="icon" :size="20" />
            <span class="text" :style="((['complain'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Санал гомдол</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="9">
          <a target="_blank" href="https://online.fliphtml5.com/ggmst/qtpn/">
            <warning-icon class="icon" :size="20" />
            <span class="text">Ашиглах заавар</span>
          </a>
        </el-menu-item>
        <el-menu-item @click="onPressToggleMenu" index="10" :class="((['phone'].includes($route.name )) ? 'active': '')">
          <router-link :to="{ name: 'phone' }">
            <phone-icon class="icon" :size="20" />
            <span class="text" :style="((['phone'].includes($route.name )) ? 'color: rgb(36, 204, 120);': '')">Холбогдох утас</span>
          </router-link>
        </el-menu-item>
        <el-menu-item @click="logout" index="11">
          <logout-icon class="icon" :size="20" />
          <span class="text">Гарах</span>
        </el-menu-item>
      </el-menu>
      <div v-if="!isCollapse" style="background-color: rgba(0,0,0, 0.1); height: 100vh; width: 20vw; position: absolute; top: 0; right: 0; z-index: 10;" @click="onPressToggleMenu"/>
    </div>
  </div>
</template>
<script>
import LogoutIcon from 'vue-material-design-icons/Logout'
import { signOut } from '../helper/auth'
import MenuIcon from 'vue-material-design-icons/Tag.vue'
import HomeIcon from 'vue-material-design-icons/TrendingUp'
import InfoIcon from 'vue-material-design-icons/AccountBox'
import MapIcon from 'vue-material-design-icons/Map'
import TimeTableIcon from 'vue-material-design-icons/Clock'
import SettlementIcon from 'vue-material-design-icons/CashCheck'
import ComplainIcon from 'vue-material-design-icons/ThumbUp.vue'
import PhoneIcon from 'vue-material-design-icons/Phone'
import WarningIcon from 'vue-material-design-icons/AlertCircleOutline.vue'
import AtomIcon from 'vue-material-design-icons/Atom'
export default {
  components: {
    LogoutIcon,
    HomeIcon,
    MenuIcon,
    InfoIcon,
    MapIcon,
    TimeTableIcon,
    SettlementIcon,
    ComplainIcon,
    PhoneIcon,
    WarningIcon,
    AtomIcon
  },
  data () {
    return {
      displayName: '',
      displayImage: '',
      dialogVisible: false,
      isCollapse: true
    }
  },
  created () {
    if (this.$root.outlet !== null && this.$root.outlet.full_name_mon !== null) {
      this.displayName = this.$root.outlet.full_name_mon
      this.displayImage = this.$root.outlet?.logo_url
    }
  },
  methods: {
    async logout () {
      signOut()
    },
    onPressToggleMenu () {
      this.isCollapse = !this.isCollapse
    }
  }
}
</script>
<style>
</style>
