<template>
  <el-row type="flex" class="title-item">
    <span class="ml10" :style="`font-size: ${fontSize}px`">{{ title }}</span>
  </el-row>
</template>

<script>
export default {
  name: 'LoginHeaderItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '24'
    }
  }
}
</script>

<style>
</style>
