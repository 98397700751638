<template>
  <div
    id="app"
    v-if="!$root.isOutletDataLoading"
    v-loading.fullscreen.lock="$root.isOutletDataLoading"
  >
    <main-header class="iconMenu" />
    <mobile-header />
    <el-container v-if="$root.isVerified" class="main-container">
      <main-sidebar class="icon" />
      <el-main :class="$route.path === '/products' ? 'p-0' : 'main-content'">
        <div
          v-if="$root.isAdmin"
          style="margin-left: 2%; margin-bottom: 1%"
          class="flex flex-row w-full justify-between"
        >
          <el-row>
            <el-select
              v-model="$root.chosenServices"
              collapse-tags
              multiple
              clearable
              placeholder="Бүх үйлчилгээ"
              class="mr10"
              @change="onChangeServiceList"
            >
              <el-option
                v-for="item in service_list"
                :key="item.name_eng.toLowerCase()"
                :label="item.name_mon"
                :value="item.name_eng.toLowerCase()"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="$root.chosenOutlets"
              collapse-tags
              multiple
              placeholder="Бүх харилцагч"
              class="mr10"
              style="width: 300px"
              filterable
              clearable
              remote
              reserve-keyword
              :remote-method="onSeachOutlet"
              :loading="isOutletLoading"
              value-key="_id"
            >
              <el-option
                v-for="item in outlets"
                :key="item._id"
                :label="item.full_name_eng"
                :value="{
                  _id: item._id,
                  full_name_eng: item.full_name_eng,
                  full_name_mon: item.full_name_mon,
                  is_delivery_by_outlet: Object.prototype.hasOwnProperty.call(
                    item,
                    'is_delivery_by_outlet'
                  )
                    ? item.is_delivery_by_outlet
                    : false,
                  is_new_order_send_sms: Object.prototype.hasOwnProperty.call(
                    item,
                    'is_new_order_send_sms'
                  )
                    ? item.is_new_order_send_sms
                    : false,
                  new_order_sms_phone: Object.prototype.hasOwnProperty.call(
                    item,
                    'new_order_sms_phone'
                  )
                    ? item.new_order_sms_phone
                    : '',
                  is_active: Object.prototype.hasOwnProperty.call(
                    item,
                    'is_active'
                  )
                    ? item.is_active
                    : false,
                }"
              >
              </el-option>
            </el-select>
            <!-- <el-button type="primary">Хайх</el-button> -->
          </el-row>
          <el-button
            type="primary"
            class="mr-2.5"
            v-if="$root.isAdmin && $route.path === '/info'"
            @click="changeMail"
            >Харилцагчийн мэйл солих</el-button
          >
        </div>
        <router-view v-if="!$root.isOutletDataLoading" />
      </el-main>
    </el-container>
    <el-container v-else>
      <div class="user-form-container">
        <step10 />
      </div>
    </el-container>
    <noSalesPartner />
    <el-drawer
      title="Харилцагчийн мэйл солих"
      :visible.sync="isChangeMail"
      :with-header="true"
      :before-close="mailChangeClose"
      custom-class="demo-drawer"
    >
      <div class="ml10 mr10">
        <el-form :model="emailChange" ref="mailRef" :rules="rulesMail">
          <el-form-item prop="email" label="Мэйл хаяг">
            <el-input
              placeholder="Солих мэйл бичнэ үү..."
              v-model="emailChange.email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="username" label="Нэвтрэх нэр">
            <el-input
              placeholder="Нэвтрэх нэр оруулна уу..."
              v-model="emailChange.username"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="float: right">
          <el-button @click="isChangeMail = false">Буцах</el-button>
          <el-button
            type="success"
            @click="updateMail()"
            :disabled="mailLoad"
            :loading="mailLoad"
            >Солих</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import step10 from "../../views/pages/createPartner/components/step10.vue";
import MainHeader from "../../components/mainHeader";
import MobileHeader from "../../components/mobileHeader";
import MainSidebar from "../../components/mainSidebar.vue";
import { getUser } from "../../helper/auth";
import mAxios from "../../helper/axiosInstance";
import noSalesPartner from "../../components/modals/noSalesPartnerModal.vue";
import * as _ from "lodash";
export default {
  name: "MainLayout",
  components: {
    MainHeader,
    MainSidebar,
    step10,
    MobileHeader,
    noSalesPartner,
  },
  data() {
    return {
      service_list: [],
      outlets: [],
      // chosen_service_list: [],
      // choosedOutletIds: [],
      isOutletLoading: false,
      isChangeMail: false,
      emailChange: {
        email: "",
        username: "",
      },
      mailLoad: false,
      rulesMail: {
        email: [
          {
            required: true,
            message: "Заавал мэйл бичнэ үү!!!",
            trigger: ["change", "blur"],
          },
        ],
        username: [
          {
            required: true,
            message: "Нэвтрэх нэр оруулна уу!!!",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  async mounted() {
    const currentUser = await getUser();
    if (currentUser) {
      this.$root.chosenOutlets = [];
      this.$root.outlet = null;
      this.$root.chosenServices = [];
      this.$root.getServices();
      this.$root.isOutletDataLoading = true;
      this.$root.outlet_id = currentUser.attributes["custom:es_id"];
      localStorage.setItem("outlet_id", currentUser.attributes["custom:es_id"]);
      if (
        Object.prototype.hasOwnProperty.call(
          currentUser.signInUserSession.accessToken.payload,
          "cognito:groups"
        )
      ) {
        this.$root.isAdmin =
          currentUser.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("admin");
      }
      if (!this.$root.isAdmin) {
        const task = [
          async () => {
            await this.$root.getChildOutlets();
          },
          async () => {
            await this.$root.getOutletById(this.$root.outlet_id);
          },
        ];
        await Promise.all(task.map((p) => p()));
      } else {
        this.getServices();
      }
      this.$root.isOutletDataLoading = false;
      this.getNoSalesPartner();
    }
  },
  methods: {
    getServices() {
      mAxios.get("getServices").then((servicesResponse) => {
        if (servicesResponse.data.status === "success") {
          this.service_list = servicesResponse.data.data;
        }
      });
    },
    onChangeServiceList() {
      this.isOutletLoading = true;
      const self = this;
      const searchFunction = () => {
        mAxios
          .post("outlet/search", {
            service_list: self.$root.chosenServices,
            include_fields: [
              "full_name_eng",
              "is_delivery_by_outlet",
              "full_name_mon",
              "service_list",
              "is_new_order_send_sms",
              "new_order_sms_phone",
              "is_active",
            ],
          })
          .then((res) => {
            if (res.data.status === "success") {
              self.outlets = res.data.data.hits;
            }
            self.isOutletLoading = false;
          });
      };
      _.debounce(searchFunction, 500)();
    },
    onSeachOutlet(query) {
      this.isOutletLoading = true;
      const self = this;
      const searchFunction = () => {
        mAxios
          .post("outlet/search", {
            search: query,
            service_list: self.$root.chosenServices,
            include_fields: [
              "full_name_eng",
              "full_name_mon",
              "service_list",
              "is_delivery_by_outlet",
              "is_new_order_send_sms",
              "new_order_sms_phone",
              "is_active",
            ],
          })
          .then((res) => {
            if (res.data.status === "success") {
              self.outlets = res.data.data.hits;
            }
            self.isOutletLoading = false;
          });
      };
      _.debounce(searchFunction, 500)();
    },
    async getNoSalesPartner() {
      const body = { outlet_ids: [] };
      this.$root.chosenOutlets.forEach((element) => {
        body.outlet_ids.push(element._id);
      });
      try {
        const response = await mAxios.post("outlet/noSalesPopUp", body);
        if (
          response.data.status === "success" &&
          response.data.data.length > 0
        ) {
          this.$store.commit("noSalesPartnerData", response.data.data);
          if (
            Object.prototype.hasOwnProperty.call(
              localStorage,
              "isShowNoSalesDailog"
            ) &&
            localStorage.isShowNoSalesDailog === "true"
          ) {
            this.$store.commit("isShow", true);
          } else {
            this.$store.commit("isShow", false);
          }
        } else {
          if (
            Object.prototype.hasOwnProperty.call(
              localStorage,
              "isShowNoSalesDailog"
            ) &&
            localStorage.isShowNoSalesDailog === "true"
          ) {
            localStorage.setItem("isShowNoSalesDailog", false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeMail() {
      this.isChangeMail = true;
    },
    updateMail() {
      this.$refs.mailRef.validate(async (valid) => {
        if (valid) {
          const payload = {
            email: this.emailChange.email,
            username: this.emailChange.username,
          };
          this.mailLoad = true;
          await mAxios
            .post("outlet/changeCognitoEmail", payload)
            .then((response) => {
              if (response.data.status === "success") {
                this.isChangeMail = false;
                this.$notify({
                  type: "success",
                  message: response.data.message,
                });
                this.emailChange.email = "";
                this.emailChange.username = "";
              } else {
                this.$notify({
                  type: "error",
                  message: response.data.message,
                });
              }
              this.mailLoad = false;
            });
        } else {
          this.$notify({
            type: "warning",
            message: "Зарим талбар бөглөөгүй байна",
          });
        }
      });
    },
    mailChangeClose() {
      this.isChangeMail = false;
      this.emailChange.email = "";
      this.emailChange.username = "";
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 800px) {
  .main-container {
    padding-left: 300px;
    padding-top: 100px;
  }
}
@media screen and (max-width: 800px) {
  .icon {
    display: none;
  }
  .iconMenu {
    display: none;
  }
}
</style>
