import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/mn'
import VueApexCharts from 'vue-apexcharts'
import ReadMore from 'vue-read-more'
import Helper from './helper/helper'
import VueCurrencyInput from 'vue-currency-input'
import * as VueGoogleMaps from 'vue2-google-maps'
import store from './store'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import mAxios from './helper/axiosInstance'
import VueQRCodeComponent from 'vue-qrcode-component'
import './assets/styles/main.css'
const plugin = {
  install () {
    Vue.helpers = Helper
    Vue.prototype.$helpers = Helper
  }
}
Vue.use(plugin)
Vue.use(VueApexCharts)
Amplify.configure(awsconfig)
Vue.use(VueCurrencyInput)
Vue.component('apexchart', VueApexCharts)
Vue.use(ElementUI, { locale })
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyCyXrKMLikgR8Kh1ps3Ys2RH7QVxNWqTjg',
    region: 'MN',
    language: 'mn',
    libraries: 'places'
  }
})
Vue.config.productionTip = false
Vue.use(ReadMore, 'readMore')
require('./assets/styles/main.css')

new Vue({
  router,
  store,
  render: h => h(App),
  data () {
    return {
      outlet_id: null,
      chosenOutlets: [],
      chosenServices: [],
      serviceList: [],
      outlet: null,
      outlets: [],
      isOutletDataLoading: false,
      isAdmin: false,
      isVerified: false,
      outletIds: []
    }
  },
  methods: {
    getServices () {
      this.serviceList = []
      mAxios.get('getServices').then(res => {
        if (res.data.status === 'success') {
          res.data.data.forEach(service => this.serviceList.push({
            name_mon: service.name_mon,
            name_eng: service.name_eng.toLowerCase()
          }))
        }
      })
    },
    async getOutletById (id) {
      // const promise = new Promise((resolve) => {
      await mAxios.get(`outlet/${id}`).then(response => {
        if (response.data.status === 'success') {
          this.outlet = response.data.data
          this.isVerified = response.data.data.is_verified
        }
        return null
      })
    },
    async getChildOutlets () {
      this.outletIds = []
      const promise = new Promise((resolve) => {
        mAxios.get('outlet/currentIdListWithChild').then(response => {
          if (response.data.status === 'success') {
            this.chosenOutlets = response.data.data
            response.data.data.map(res => {
              this.outletIds.push(res._id)
            })
          } else {
            this.chosenOutlets = []
          }
          resolve()
        }).catch(e => {
          resolve()
        })
      })
      const result = await promise
      return result
    }
  }

}).$mount('#app')
