<template>
  <div class="aboutImage">
    <login-header />
      <el-row type="flex" justify="center" align="middle" style="top: 180px">
        <el-col :span="9" :offset="3">
          <el-row type="flex" justify="space-around" style="flex-direction: column">
            <span style="font-size: 36px; color: #231F20">ТОК ТОК-ТОЙ<br><strong style="color: #FFB500;">ХАМТАРЧ АЖИЛЛАМААР<br>БАЙНА УУ?</strong></span>
            <title-item title="Холбоо барих" style="margin-top: 40px; margin-bottom: 40px"/>
            <el-row type="flex" justify="space-between" :gutter="10" class="contact-container">
              <el-col :span="12">
                <el-row type="flex" justify="space-between" style="flex-direction: column; gap: 5px">
                  <a type="flex" class="contact-body">
                    <img src="../assets/sociel/phone.svg" alt="">
                    <span>77667766</span>
                  </a>
                  <el-row style="padding: 16px; background-color: #F8F8F8; border-radius: 12px; height: 110px">
                    <span>Монгол улс, Улаанбаатар хот, Хан-уул дүүрэг, 3-р хороо, Үйлдвэрийн гудамж, Гоёо төв оффис</span>
                  </el-row>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row type="flex" justify="space-between" style="flex-direction: column; gap: 5px; cursor: pointer">
                  <a href="https://www.facebook.com/Toktok.mn" target="_blank" class="contact-body">
                    <img src="../assets/sociel/facebook.svg" alt="">
                    <span>Facebook | Toktok.mn</span>
                  </a>
                  <a href="https://www.instagram.com/toktok_delivery/" target="_blank" class="contact-body">
                    <img src="../assets/sociel/instagram.svg" alt="">
                    <span>Instagram | Toktok.mn</span>
                  </a>
                  <a class="contact-body">
                    <img src="../assets/sociel/mail.svg" alt="">
                    <span>salesmanager@toktok.mn</span>
                  </a>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row type="flex" justify="center" align="middle" style="flex-direction: column;">
            <img src="../assets/sociel/Group 34321 1.png" alt="" height="565px" width="496px">
          </el-row>
        </el-col>
      </el-row>
    </div>
</template>

<script>
import loginHeader from '../components/items/loginHeader.vue'
import TitleItem from '../components/items/titleItem.vue'
export default {
  name: 'Contract',
  components: {
    loginHeader,
    TitleItem
  }
}
</script>

<style>
</style>
