import * as dayjs from 'dayjs'
export default {
  formatDate (date, format = 'YYYY/MM/DD HH:mm:ss') {
    return dayjs(date).format(format)
  },
  difference (date1, date2, format = 'm') {
    return dayjs(date1).diff(date2, format)
  },
  formatPrice (value) {
    return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  getClientData () {
    return JSON.parse(window.localStorage.getItem('clientData'))
  },
  setLocalStorageItem (key, value) {
    localStorage.setItem(key, value)
  },
  getLocalStorageItem (key) {
    return window.localStorage.getItem(key)
  },
  getBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      let imgResult = ''
      reader.readAsDataURL(file)
      reader.onload = () => {
        imgResult = reader.result
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.onloadend = () => {
        resolve(imgResult.split(',')[1])
      }
    })
  },
  checkProperty (obj, property) {
    return Object.prototype.hasOwnProperty.call(obj, property)
  }
}
