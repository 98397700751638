<template>
  <div class="step">
    <el-form :model="step6Form" ref="step6Form">
      <!-- <el-row class="mt10">
        <span>Үйлчилгээний нөхцөл</span>
      </el-row> -->
      <el-row type="flex" justify="center" class="m20">
        <title-item title="Үйлчилгээний нөхцөл" />
      </el-row>
      <el-row type="flex" justify="center" class="mt20">
        <span style="font-size: 12px; font-weight: 600">Үйлчилгээний нөхцөлийг таны сонгосон бараа, бүтээгдэхүүний ангилал болон хүргэлтийн хэлбэрээс хамаарч тогтоодог байгаа шүү.</span>
      </el-row>
      <el-row :gutter="50" class="mt20">
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-row type="flex" justify="center" class="mt30">
            <div class="box-card" :style="step6Form.toktok === true ? 'border-color: #FFB500' : ''" @click="setType('toktok')">
              <el-row>
                <el-col :span="8" class="text-right" :xs="4">
                  <el-checkbox @change="isChanged(step6Form.toktok, 'toktok')" v-model="step6Form.toktok" class="pr10" />
                </el-col>
                <el-col :span="16" :xs="20">
                  <div style="font-size: 16px; font-weight: 700">
                    <span>ТОК ТОК хүргэлт сонгох</span>
                  </div>
                  <div style="font-size: 16px; font-weight: 500">
                    <span>Захиалга тутамд - {{ step6Form.toktok && step6Form.isPercent ? step6Form.toktok_percent + '%' : step6Form.toktok === false && step6Form.isPercent ?  step6Form.toktok_percent + '%' : this.step6Form.supplier && step6Form.isPercent === false ? this.step6Form.toktok_percent + '%' : this.step6Form.toktok === true && step6Form.isPercent ? this.step6Form.toktok_percent + '%' : step6Form.toktok_amount + '₮' }}</span>
                  </div>
                </el-col>
              </el-row>
              <div class="text-center">
                <el-button :disabled="step6Form.supplier" type="warning" class="mt20 tt-button-yellow" @click="dialogVisible = true">Тохиролцоо хийх</el-button>
              </div>
            </div>
          </el-row>
        </el-col>
        <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-row type="flex" justify="center" class="mt30">
            <div class="box-card" :style="step6Form.supplier === true ? 'border-color: #FFB500' : ''" @click="setType('supplier')">
              <el-row>
                <el-col :span="8" class="text-right" :xs="4">
                  <el-checkbox @change="isChanged(step6Form.supplier, 'supplier')" v-model="step6Form.supplier" class="pr10"/>
                </el-col>
                <el-col :span="12" :xs="20">
                  <div style="font-size: 16px; font-weight: 700">
                    <span>Харилцагч өөрөө хүргэх</span>
                  </div>
                  <div style="font-size: 16px; font-weight: 500">
                    <span>Захиалга тутамд - {{ step6Form.supplier && step6Form.isPercent ? step6Form.supplier_percent + '%' : step6Form.supplier === false && step6Form.isPercent ? step6Form.supplier_percent + '%' : step6Form.toktok && step6Form.isPercent === false ? step6Form.supplier_percent + '%' : step6Form.supplier_amount + '₮' }}</span>
                  </div>
                </el-col>
              </el-row>
              <div class="text-center">
                <el-button :disabled="step6Form.toktok" type="warning" class="mt20 tt-button-yellow" @click="dialogVisible = true">Тохиролцоо хийх</el-button>
              </div>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <!-- <div class="footer-container mt80">
      <buttons :validate="validate" :title="'ҮРГЭЛЖЛҮҮЛЭХ'"/>
    </div> -->
    <div class="footer-container mt20">
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
      </el-row>
      <el-row class="mt10 tt-button-text">
        <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
      </el-row>
    </div>
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="widthClass"
      :size="size"
    >
      <div class="dialog-container" style="font-size:20px;">
        <div style="display: flex; flex-wrap:wrap;">
          <span>{{step6Form.toktok === true ? 'ТОК ТОК-оор хүргүүлэх' : 'Харилцагч өөрөө хүргэх'}}</span><br/>
          <span class="ml10" v-if="step6Form.supplier">
            <span>(Төгрөгөөр </span>
            <el-switch
              v-model="step6Form.isPercent"
              active-color="#13ce66"
              inactive-color="#409eff"
              @change="changeType">
            </el-switch>
            <span> Хувиар)</span>
          </span>
        </div>
        <el-input v-if="step6Form.isPercent" class="mt20" v-model="change_percent">
          <template slot="append">%</template>
        </el-input>
        <el-input v-else class="mt20" v-model="change_amount">
          <template slot="append">₮</template>
        </el-input>
        <div class="mt20">
          <el-row type="flex" justify="center">
            <el-button type="warning" class="tt-button-yellow" @click="setData">ҮРГЭЛЖЛҮҮЛЭХ</el-button>
          </el-row>
          <el-row class="mt10 tt-button-text" type="flex" justify="center">
            <el-button type="text" @click="dialogVisible = false">БУЦАХ</el-button>
          </el-row>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// import Buttons from './buttons.vue'
import Button from '../../../../components/items/button'
import titleItem from '../../../../components/items/titleItem'
export default {
  name: 'step6',
  components: {
    // Buttons,
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step6Form = this.data
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '60%'
    } else {
      this.size = '30%'
    }
  },
  data () {
    return {
      change_amount: '3000',
      dialogVisible: false,
      step6Form: {
        isPercent: true,
        toktok: true,
        supplier: false,
        toktok_percent: '20',
        toktok_amount: '3000',
        supplier_percent: '5',
        supplier_amount: '3000'
      },
      change_percent: '20',
      size: ''
    }
  },
  methods: {
    setType (type) {
      if (type === 'toktok') {
        this.step6Form.toktok = true
        this.step6Form.supplier = false
      } else {
        this.step6Form.toktok = false
        this.step6Form.supplier = true
      }
    },
    changeType (type) {
      if (type === false) {
        this.step6Form.supplier_percent = '5'
      } else {
        this.step6Form.supplier_amount = '3000'
      }
    },
    validate (type) {
      if (type === 'increase') {
        this.onComplete('step7', type, this.step6Form)
      } else {
        this.onComplete('step5', type, this.step6Form)
      }
    },
    isChanged (data, type) {
      if (data === true && type === 'toktok') {
        this.step6Form.isPercent = true
        this.step6Form.supplier = false
        this.step6Form.supplier_percent = '5'
        this.step6Form.supplier_amount = '3000'
      } else {
        this.step6Form.isPercent = true
        this.step6Form.toktok = false
        this.step6Form.toktok_percent = '20'
        this.step6Form.toktok_amount = '3000'
      }
    },
    setData () {
      if (this.step6Form.toktok === true && this.step6Form.isPercent === true) {
        this.step6Form.toktok_percent = this.change_percent
      } else if (this.step6Form.toktok === true && this.step6Form.isPercent === false) {
        this.step6Form.toktok_amount = this.change_amount
      } else if (this.step6Form.supplier === true && this.step6Form.isPercent) {
        this.step6Form.supplier_percent = this.change_percent
      } else if (this.step6Form.supplier === true && this.step6Form.isPercent === false) {
        this.step6Form.supplier_amount = this.change_amount
      }
      this.dialogVisible = false
      this.change_percent = ''
      this.change_amount = ''
    }
  }
}
</script>

<style>
@media screen and (max-width: 1000px) {
  .widthClass {
    width: 90% !important
  }
}
.el-checkbox__label {
  font-size: 18px
}
.el-checkbox__inner:hover {
    border-color: orange;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: orange;
    border-color: orange;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: orange;
}
.dialog-container {
  padding: 0px 20px 10px 20px;
}
.el-dialog {
  border-radius: 30px;
}
.el-dialog .el-dialog__header {
    border-bottom: 1px solid #ffffff;
}
.step .el-input__inner {
  border-radius: 4px 0px 0px 4px !important
}
</style>
