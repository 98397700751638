<template>
  <div>
    <login-header />
    <el-row class="main-container">
      <el-col :span="16" class="left-container">
        <!-- <about-header /> -->
        <el-row type="flex" class="mt50 top-description">
          <img src="../assets/Rectangle 358.png" alt="" style="width: 100%">
          <el-row><span class="black-content">УЛААНБААТАР ХОТЫН</span> <br> <span class="yellow-content">ХАМГИЙН ТОМ ХООЛ ХҮРГЭЛТИЙН АПП</span>
          </el-row>
          <el-row type="flex" class="content-container">
            <el-row type="flex" align="middle" class="three-content">
              <img src="../assets/login/101.png" class="w-85" />
              <el-row type="flex" style="flex-direction: column" align="bottom" class="ml20">
                <div class="bold-text-login">+
                  <count-to :start-val="0" :end-val="800000" :duration="2600" />
                </div>
                <span class="text-20">гаруй хэрэглэгч</span>
              </el-row>
            </el-row>
            <el-row type="flex" align="middle" class="three-content">
              <img src="../assets/login/100.png" class="w-85" />
              <el-row type="flex" style="flex-direction: column" align="bottom" class="ml20">
                <div class="bold-text-login">+
                  <count-to :start-val="0" :end-val="500" :duration="2600" />
                </div>
                <span class="text-20">гаруй харилцагч</span>
              </el-row>
            </el-row>
            <el-row type="flex" align="middle" class="three-content">
              <img src="../assets/login/102.png" class="w-85" />
              <el-row type="flex" style="flex-direction: column" align="bottom" class="ml20">
                <div class="bold-text-login">+
                  <count-to :start-val="0" :end-val="400" :duration="2600" />
                </div>
                <span class="text-20">гаруй хүргэгч</span>
              </el-row>
            </el-row>
          </el-row>
          <el-row class="login-description">
            <span>Ток ток нь хоол, хүнс, бялуу, кофе цай гэх мэт 4 төрлийн категорид <br> зориулсан технологийн шийдэл, хүргэлтийн үйлчилгээг санал болгодог.</span>
          </el-row>
        </el-row>
      </el-col>
      <el-col :span="8" class="right-container">
        <title-item title="Нууц үг сэргээх" class="mt100 mb10"/>
        <span style="font-size: 10px; font-weight: 550">Таны {{email}} и-мэйл хаягт ирсэн нууц үг сэргээх код оруулна уу!.</span>
        <el-form :model="model" ref="form" label-position="top" class="mt30" :rules="rules" @submit.native.prevent="onSubmit" autocomplete="off">
          <el-form-item label="Баталгаажуулах код" prop="code">
            <input type="text" v-model="model.code" placeholder="Баталгаажуулах код" class="inputClass" autocomplete="off"/>
          </el-form-item>
          <el-form-item label="Шинэ нууц үг" prop="newpassword">
            <input v-model="model.newpassword" type="password" placeholder="Нууц үг" class="inputClass"  autocomplete="new-password"/>
          </el-form-item>
          <el-form-item label="Нууц үг давтах" prop="confirmNewPassword">
            <input v-model="model.confirmNewPassword" type="password" placeholder="Нууц үг" class="inputClass"  autocomplete="new-password"/>
          </el-form-item>
          <Button class="mt20" :loading="loading" title="Шинэчлэх" icon="" v-on:click.native="onSubmit"/>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { changePasswordSumbit } from '../helper/auth'
import countTo from 'vue-count-to'
import loginHeader from '../components/items/loginHeader.vue'
import titleItem from '../components/items/titleItem.vue'
import Button from '../components/items/button.vue'
export default {
  components: {
    loginHeader,
    titleItem,
    Button,
    countTo
  },
  name: 'changePassword',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Нууц үгээ оруулна уу!'))
      } else {
        if (this.model.confirmNewPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Нууц үгээ оруулна уу!'))
      } else if (value !== this.model.newpassword) {
        callback(new Error('Нууц үг зөрсөн байна!'))
      } else {
        callback()
      }
    }
    return {
      model: {
        code: '',
        newpassword: '',
        confirmNewPassword: ''
      },
      loading: false,
      rules: {
        code: [
          {
            required: true,
            message: 'Нэвтрэх нэр хоосон байна',
            trigger: 'blur'
          }
        ],
        newpassword: [
          { validator: validatePass, trigger: 'blur' },
          {
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: 'blur'
          }
        ],
        confirmNewPassword: [
          { validator: validatePass2, trigger: 'blur' },
          {
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: 'blur'
          }
        ]
      },
      email: ''
    }
  },
  mounted () {
    this.email = this.$route.params.email
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          changePasswordSumbit({
            username: this.$route.params.username,
            code: this.model.code,
            new_password: this.model.newpassword
          })
            .then(() => {
              this.loading = false
              this.$message.success({
                message: 'Амжилттай шинэчлэлээ',
                type: 'success'
              })
              this.$router.push({
                name: 'login'
              })
            })
            .catch(error => {
              if (error.message === 'Attempt limit exceeded, please try after some time.') {
                this.$message.error('5 удаа буруу оролдлого хийсэн байна. 5 минутын дараа дахин оролдоно уу?')
              } else if (error.message === 'Password does not conform to policy: Password must have uppercase characters') {
                this.$message.error('Том үсэг оруулна уу?')
              } else if (error.message === 'Password does not conform to policy: Password must have symbol characters') {
                this.$message.error('Тусгай тэмдэгт оруулна уу? Жишээ нь: @, #...')
              } else {
                this.$message.error(error.message)
              }
              this.loading = false
            })
        } else {
          return null
        }
      })
    }
  }
}
</script>
<style scoped>
</style>
