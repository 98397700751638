<template>
  <el-progress :text-inside="true" :show-text="false" :stroke-width="2" :color="tt_orange" :percentage="percentage" status="warning" class="mb10"></el-progress>
</template>

<script>
export default {
  name: 'progress',
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tt_orange: '#7CBB00'
    }
  }
}
</script>

<style>
</style>
