<template>
  <div class="step">
    <!-- <el-row class="mt10">
      <span class="gray-color">Гэрээтэй сайтар танилцана уу.</span>
    </el-row> -->
    <el-row type="flex" justify="center" class="m20">
      <title-item title="Гэрээтэй сайтар танилцана уу" />
    </el-row>
    <el-row type="flex" justify="center">
      <span style="font-size: 12px; font-weight: 600">Гэрээтэй танилцан үргэлжлүүлэх дарснаар хамтран ажиллах хүсэлт илгээгдэж, таны хавсралт мэдээллийг шалган манай ажилтан эргэн холбогдох болно.</span>
    </el-row>
    <el-row class="pdf_padding">
      <pdf v-for="(page, index) in numPages"
			:key="index"
			:src="pdf_url"
			:page="page"></pdf>
    </el-row>
    <el-row class="pdf_padding">
      <pdf v-for="(page1, index1) in numSpecialPages"
			:key="index1"
			:src="special_pdf_url"
			:page="page1"></pdf>
    </el-row>
    <div class="footer-container mt20">
      <el-row class="tt-button-text">
        <a :href="step6Data.toktok === true ? 'https://image.toktok.mn/client-contract/%D0%A2%D1%83%D1%81%D0%B3%D0%B0%D0%B8%CC%86%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%20%D0%B7%D3%A9%D0%B2%20-%20Copy.pdf' : 'https://image.toktok.mn/client-contract/%D0%A2%D1%83%D1%81%D0%B3%D0%B0%D0%B8%CC%86%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D%20-%20%D0%A5%D0%B0%D1%80%D0%B8%D0%BB%D1%86%D0%B0%D0%B3%D1%87%D0%B8%D0%B8%CC%86%D0%BD%20%D1%85%D2%AF%D1%80%D0%B3%D1%8D%D0%BB%D1%82.pdf'" target="_blank"><el-button style="font-size: 14px" :loading="downloadLoading" type="text">Хэвлэх /тусгай гэрээ/</el-button></a>
      </el-row>
      <el-row class="tt-button-text">
        <a href="https://image.toktok.mn/client-contract/%D0%A5%D0%B0%D0%BC%D1%82%D1%80%D0%B0%D0%BD%20%D0%B0%D0%B6%D0%B8%D0%BB%D0%BB%D0%B0%D1%85%20%D0%B5%D1%80%D3%A9%D0%BD%D1%85%D0%B8%D0%B8%CC%86%20%D0%B3%D1%8D%D1%80%D1%8D%D1%8D.pdf" target="_blank"><el-button style="font-size: 14px" :loading="downloadLoading" type="text">Хэвлэх /Ерөнхий гэрээ/</el-button></a>
      </el-row>
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="register" :loading="loading" title="Дуусгах" style="font-size: 16px; width: 343px"/>
      </el-row>
      <el-row class="mt10 tt-button-text">
        <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import Button from '../../../../components/items/button'
import titleItem from '../../../../components/items/titleItem'
// import Buttons from './buttons.vue'
export default {
  name: 'step9',
  components: {
    // Buttons,
    pdf,
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => {}
    },
    register: {
      type: Function,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    downloadLoading: {
      type: Boolean,
      default: false
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step6Data = JSON.parse(localStorage.getItem('vuex')).registrationStep.steps.step6
    this.pdf_url = pdf.createLoadingTask(JSON.parse(localStorage.getItem('vuex')).registrationStep.steps.step6.toktok === true ? 'https://d2ib3wb1gajb95.cloudfront.net/client_special_contract.pdf' : 'https://d2ib3wb1gajb95.cloudfront.net/client_delivery_contract.pdf')
    this.pdf_url.promise.then(pdf => {
      this.numPages = pdf.numPages
    })
    this.special_pdf_url = pdf.createLoadingTask('https://d2ib3wb1gajb95.cloudfront.net/client_contract.pdf')
    this.special_pdf_url.promise.then(pdf => {
      this.numSpecialPages = pdf.numPages
    })
  },
  data () {
    return {
      step6Data: null,
      pdf_url: '',
      special_pdf_url: '',
      numPages: 0,
      numSpecialPages: 0,
      step9Form: {
        step9: ''
      }
    }
  },
  methods: {
    validate (type) {
      if (type === 'increase') {
        this.onComplete('step10', type, this.step9Form)
      } else {
        this.onComplete('step8', type, this.step9Form)
      }
    }
  }
}
</script>

<style scoped>
.pdf_padding {
  width: 100%;
  padding: 10px 400px 0px 400px;
}
</style>
