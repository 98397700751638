<template>
  <el-header class="headar-create-partner">
    <el-row :gutter="20" type="flex" justify="space-between" align="middle" class="hiddenClass">
      <el-col :span="4">
        <router-link :to="{ name: 'login' }">
          <img src="../assets/logo.png" class="img" />
        </router-link>
      </el-col>
      <el-col :span="4">
        <el-button type="text" size="large" class="header_button"><i class="el-icon-reading mr10"></i>Бидний тухай</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="text" size="large" class="header_button" @click="$router.push({ path: '/SignUp' })"><i class="el-icon-plus mr10"></i>Хамтран ажиллах</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="text" size="large" class="header_button"><i class="el-icon-phone-outline mr10"></i>Холбоо барих</el-button>
      </el-col>
      <el-col :span="4" v-if="this.$route.path === '/create-partner'">
        <el-button type="text" size="large" class="header_button" @click="logout"><i class="el-icon-close mr10"></i>Гарах</el-button>
      </el-col>
      <el-col :span="4" v-if="this.$route.path === '/SignUp' || this.$route.path === '/forgot-password' || this.$route.path === '/confirmCode' || this.$route.path === '/login' || this.$route.path === '/changePassword'">
        <el-button type="text" size="large" class="header_button" @click="$router.push({ path: '/login' })"><i class="el-icon-user mr10"></i>Нэвтрэх</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="mobileClass">
      <el-col :span="12">
        <router-link :to="{ name: 'login' }">
          <img src="../assets/logo.png" class="img" />
        </router-link>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-dropdown  @command="handleItem">
          <i class="el-icon-s-operation" style="font-size: 30px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="info">Бидний тухай</el-dropdown-item>
            <el-dropdown-item command="signup">Хамтран ажиллах</el-dropdown-item>
            <el-dropdown-item command="contact">Холбоо барих</el-dropdown-item>
            <el-dropdown-item v-if="this.$route.path === '/SignUp' || this.$route.path === '/forgot-password' || this.$route.path === '/confirmCode' || this.$route.path === '/login' || this.$route.path === '/changePassword'" command="login">Нэвтрэх</el-dropdown-item>
            <el-dropdown-item v-if="this.$route.path === '/create-partner'"  command="logout">Гарах</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
import { signOut } from '../helper/auth'
export default {
  name: 'createPartnerheader',
  methods: {
    async logout () {
      signOut()
    },
    handleItem (item) {
      if (item === 'signup') {
        this.$router.push({ name: 'SignUp' })
      } else if (item === 'login') {
        this.$router.push({ name: 'login' })
      } else if (item === 'logout') {
        this.logout()
      }
    }
  }
}
</script>

<style scoped>
.header_button {
  font-size: 18px; font-weight: bold; color: #483636
}
.el-button--text:focus, .el-button--text:hover {
  color: gray
}
</style>
