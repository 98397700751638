<template>
  <el-row type="flex" justify="space-between" class="sign-up-footer">
    <div class="sign-up-item">
      <img src="../../assets/signUp/1.png" alt="">
      <div style="font-size: 17px; font-weight: 700">Борлуулалтаа <br> нэмэгдүүлэх</div>
      <div style="font-size: 10px">Шинэ суваг, <br> нэмэлт орлого</div>
    </div>
    <div class="sign-up-item">
      <img src="../../assets/signUp/2.png" alt="">
      <div style="font-size: 17px; font-weight: 700">Маркетингийн<br>үйлчилгээ</div>
      <div style="font-size: 10px">Баннер, мессеж,<br>контент</div>
    </div>
    <div class="sign-up-item">
      <img src="../../assets/signUp/3.png" alt="">
      <div style="font-size: 17px; font-weight: 700">Хүргэлтийн <br> үйлчилгээ</div>
      <div style="font-size: 10px">Тлк ток хүргэх/<br>Харилцагч хүргэх</div>
    </div>
    <div class="sign-up-item">
      <img src="../../assets/signUp/4.png" alt="">
      <div style="font-size: 17px; font-weight: 700">Хамтарсан<br>идэвхжүүлэлт</div>
      <div style="font-size: 10px">Борлуулалтыг дэмжих<br>нэмэлт ажлууд</div>
    </div>
    <div class="sign-up-item">
      <img src="../../assets/signUp/5.png" alt="">
      <div style="font-size: 17px; font-weight: 700">Борлуулалтын<br>мэдээллээ хянах</div>
      <div style="font-size: 10px">Удирдлагын болон<br>захиалгын автомат систем</div>
    </div>
    <div class="sign-up-item">
      <img src="../../assets/signUp/6.png" alt="">
      <div style="font-size: 17px; font-weight: 700">Брэндээ<br>таниулах</div>
      <div style="font-size: 10px">+800k хэрэглэгчдэд<br>сурталчлах</div>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'SignUpItem'
}
</script>

<style>
</style>
