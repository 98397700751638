<template>
  <div class="step">
    <div>
      <el-row type="flex" justify="center">
        <title-item title="Цагийн хуваарь" />
      </el-row>
      <el-row type="flex" justify="center" class="mt10">
        <span style="font-size: 12px; font-weight: 600">Та өөрийн дэлгүүр, рестораны ажиллах цагийн хуваарийн мэдээллээ оруулна уу.</span>
      </el-row>
      <el-form :model="step3Form" :rules="rules" ref="step3Form" class="mt20">
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Даваа">
              <el-time-select
                @change="setTime"
                placeholder="Нээх цаг"
                v-model="step3Form.mon.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.mon.endTime, 'mon')"
                placeholder="Хаах цаг"
                v-model="step3Form.mon.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Мягмар">
              <el-time-select
                placeholder="Нээх цаг"
                v-model="step3Form.tue.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.tue.endTime, 'tue')"
                placeholder="Хаах цаг"
                v-model="step3Form.tue.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Лхагва">
              <el-time-select
                placeholder="Нээх цаг"
                v-model="step3Form.wed.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.wed.endTime, 'wed')"
                placeholder="Хаах цаг"
                v-model="step3Form.wed.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Пүрэв">
              <el-time-select
                placeholder="Нээх цаг"
                v-model="step3Form.thu.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.thu.endTime, 'thu')"
                placeholder="Хаах цаг"
                v-model="step3Form.thu.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Баасан">
              <el-time-select
                placeholder="Нээх цаг"
                v-model="step3Form.fri.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.fri.endTime, 'fri')"
                placeholder="Хаах цаг"
                v-model="step3Form.fri.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Бямба">
              <el-time-select
                placeholder="Нээх цаг"
                v-model="step3Form.sat.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.sat.endTime, 'sat')"
                placeholder="Хаах цаг"
                v-model="step3Form.sat.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="6" :offset="6" class="text-right" :xs="{span: 12, offset: 0}" :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 6 }" :xl="{ span: 6, offset: 6 }">
            <el-form-item label="Ням">
              <el-time-select
                placeholder="Нээх цаг"
                v-model="step3Form.sun.startTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item>
              <el-time-select
                @change="generateTime(step3Form.sun.endTime, 'sun')"
                placeholder="Хаах цаг"
                v-model="step3Form.sun.endTime"
                :picker-options="pickerOption"
              >
              </el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="footer-container mt20">
      <el-row type="flex" justify="center">
        <Button type="warning" v-on:click.native="validate('increase')" title="Үргэлжлүүлэх" style="font-size: 16px; width: 343px"/>
      </el-row>
      <el-row class="mt10 tt-button-text">
        <el-button type="text"  @click="validate('decrease')" style="font-size: 16px">Буцах</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import Button from '../../../../components/items/button'
import titleItem from '../../../../components/items/titleItem'

export default {
  name: 'step3',
  components: {
    Button,
    titleItem
  },
  props: {
    onComplete: {
      type: Function,
      default: () => null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.step3Form = this.data
  },
  data () {
    return {
      pickerOption: {
        start: '00:05',
        step: '00:05',
        end: '23:55'
      },
      step3Form: {
        mon: {
          startTime: '',
          endTime: ''
        },
        tue: {
          startTime: '',
          endTime: ''
        },
        wed: {
          startTime: '',
          endTime: ''
        },
        thu: {
          startTime: '',
          endTime: ''
        },
        fri: {
          startTime: '',
          endTime: ''
        },
        sun: {
          startTime: '',
          endTime: ''
        },
        sat: {
          startTime: '',
          endTime: ''
        }
      },
      rules: {
        position: [
          { required: true, message: 'Та албан тушаалаа заавал оруулна уу!', trigger: 'blur' }
        ],
        name: [
          { required: true, message: 'Та харилцагчийн нэрээ заавал оруулна уу!', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Та утасны дугаараа заавал оруулна уу!', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Та и-мэйл хаягаа заавал оруулна уу!', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    setTime (time) {
      this.step3Form.tue.startTime = time
      this.step3Form.wed.startTime = time
      this.step3Form.thu.startTime = time
      this.step3Form.fri.startTime = time
      this.step3Form.sun.startTime = time
      this.step3Form.sat.startTime = time
    },
    generateTime (time, day) {
      // if (this.step3Form[day].startTime >= time && this.step3Form[day].startTime !== null) {
      //   this.step3Form[day].endTime = ''
      //   if (day === 'mon') {
      //     this.step3Form.mon.endTime = ''
      //     this.step3Form.tue.endTime = ''
      //     this.step3Form.wed.endTime = ''
      //     this.step3Form.thu.endTime = ''
      //     this.step3Form.fri.endTime = ''
      //     this.step3Form.sat.endTime = ''
      //     this.step3Form.sun.endTime = ''
      //   }
      //   this.$message({
      //     title: 'Алдаа гарлаа',
      //     message: 'Тухайн гаригийн нээх цагаас хаах цаг эрт байж болохгүй.',
      //     type: 'error'
      //   })
      // }
      if (day === 'mon') {
        this.step3Form.tue.endTime = time
        this.step3Form.wed.endTime = time
        this.step3Form.thu.endTime = time
        this.step3Form.fri.endTime = time
        this.step3Form.sun.endTime = time
        this.step3Form.sat.endTime = time
      }
    },
    validate (type) {
      if (type === 'increase') {
        this.$refs.step3Form.validate((valid) => {
          if (valid) {
            if (!['', undefined, null].includes(this.step3Form.mon.endTime) && !['', undefined, null].includes(this.step3Form.mon.startTime)) {
              if (!['', undefined, null].includes(this.step3Form.tue.endTime) && !['', undefined, null].includes(this.step3Form.tue.startTime)) {
                if (!['', undefined, null].includes(this.step3Form.wed.endTime) && !['', undefined, null].includes(this.step3Form.wed.startTime)) {
                  if (!['', undefined, null].includes(this.step3Form.thu.endTime) && !['', undefined, null].includes(this.step3Form.thu.startTime)) {
                    if (!['', undefined, null].includes(this.step3Form.fri.endTime) && !['', undefined, null].includes(this.step3Form.fri.startTime)) {
                      if (!['', undefined, null].includes(this.step3Form.sat.endTime) && !['', undefined, null].includes(this.step3Form.sat.startTime)) {
                        if (!['', undefined, null].includes(this.step3Form.sun.endTime) && !['', undefined, null].includes(this.step3Form.sun.startTime)) {
                          this.loading = true
                          this.onComplete('step4', type, this.step3Form)
                        } else {
                          this.step = 'step3'
                          this.$notify.error({
                            title: 'Алдаа',
                            message: 'Цагийн хуваарь дутуу байна'
                          })
                        }
                      } else {
                        this.step = 'step3'
                        this.$notify.error({
                          title: 'Алдаа',
                          message: 'Цагийн хуваарь дутуу байна'
                        })
                      }
                    } else {
                      this.step = 'step3'
                      this.$notify.error({
                        title: 'Алдаа',
                        message: 'Цагийн хуваарь дутуу байна'
                      })
                    }
                  } else {
                    this.step = 'step3'
                    this.$notify.error({
                      title: 'Алдаа',
                      message: 'Цагийн хуваарь дутуу байна'
                    })
                  }
                } else {
                  this.step = 'step3'
                  this.$notify.error({
                    title: 'Алдаа',
                    message: 'Цагийн хуваарь дутуу байна'
                  })
                }
              } else {
                this.step = 'step3'
                this.$notify.error({
                  title: 'Алдаа',
                  message: 'Цагийн хуваарь дутуу байна'
                })
              }
            } else {
              this.step = 'step3'
              this.$notify.error({
                title: 'Алдаа',
                message: 'Цагийн хуваарь дутуу байна'
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.onComplete('step2', type, this.step3Form)
      }
    }
  }
}
</script>

<style scoped>
</style>
