import * as axios from 'axios'
import {
  Message
} from 'element-ui'
import {
  getToken
} from './auth'
const mAxios = axios.create({
  timeout: 30000,
  //   timeout: 10000,
  baseURL: process.env.VUE_APP_CLIENT,
  headers: {
    // Authorization: 'Bearer'
  }
})

mAxios.interceptors.request.use(async (config) => {
  const accessToken = await getToken()
  config.headers.common.Authorization = `Bearer ${accessToken}`
  return config
})

mAxios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.status !== 'success') {
      // ElMessage({
      //   message: response.data.message,
      //   type: 'error'
      // })
    }
    return response
  },
  async (error) => {
    if (error.response) {
      // console.log(error.response);
      // The client was given an error response (5xx, 4xx)
      if (error.response.data) {
        Message({
          message: error.response.data.message,
          type: 'error'
        })
        // return error
        Promise.reject(error)
      }
      if (error.response.status === 401) {
        return axios(error.config)
      } else {
        return Promise.reject(error)
      }
    } else if (error.request) {
      // The client never received a response, and the request was never left
      console.log(error.request)
    } else {
      // Anything else
    }
    return Promise.reject(error)
  }
)

export default mAxios
