<template>
  <el-dialog
    title="Анхааруулга!"
    :visible.sync="this.$store.state.isShowNoSalesPartnerModal"
    :width="size"
    :before-close="closeFuntion"
    center>
    <div class="desc">Борлуулалт гараагүй 60 хоног болсон тохиолдолд автоматаар гэрээ цуцлагдаж салбар хаагдахыг анхаараарай.</div>
    <el-table :data="noSalesData" @cell-click="handleItem">
      <el-table-column type="index" label="№"></el-table-column>
      <el-table-column prop="full_name_eng" label="Салбарын нэр"></el-table-column>
      <el-table-column prop="no_sales_days" label="Борлуулалт гараагүй өдөр"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: 'noSalesPartnerModal',
  created () {
    this.noSalesData = this.$store.state.noSalesPartnerData
    if (window.innerWidth < 600) {
      this.size = '95%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '65%'
    } else {
      this.size = '30%'
    }
  },
  data () {
    return {
      noSalesData: [],
      size: ''
    }
  },
  methods: {
    closeFuntion () {
      this.$store.commit('isShow', false)
      localStorage.setItem('isShowNoSalesDailog', false)
    },
    handleItem (row) {
      this.$router.push({ name: 'info', params: { id: row._id } })
      this.$store.commit('isShow', false)
      localStorage.setItem('isShowNoSalesDailog', false)
    }
  }
}
</script>

<style>
  .desc {
    background-color: #E6A23C; padding: 10px; border-radius: 5px; color: white; word-break: break-word; text-align: justify;
  }
</style>
