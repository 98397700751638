<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { getUser } from './helper/auth'
export default {
  async created () {
    const currentUser = await getUser()
    if (currentUser) {
      if (Object.prototype.hasOwnProperty.call(currentUser.signInUserSession.accessToken.payload, 'cognito:groups')) {
        this.$root.isAdmin = currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('admin')
        if (this.$root.isAdmin) {
          this.$root.isVerified = true
        }
      }
    }
  }
}
</script>
<style lang="css">
/* Import your CSS file */
@import './index.css';
/* Additional component-specific styles */
</style>
