<template>
  <div class="aboutImage">
    <!-- <about-header /> -->
    <login-header />
    <el-row type="flex" justify="center" style="flex-direction: column; padding-top: 142px" align="middle">
      <img src="../assets/about/0.jpg" alt="" class="imageClass">
      <img src="../assets/about/1.jpg" alt="" class="imageClass">
      <img src="../assets/about/2.jpg" alt="" class="imageClass">
      <img src="../assets/about/3.jpg" alt="" class="imageClass">
      <img src="../assets/about/4.jpg" alt="" class="imageClass">
      <img src="../assets/about/5.jpg" alt="" class="imageClass">
      <img src="../assets/about/6.jpg" alt="" class="imageClass">
      <img src="../assets/about/7.jpg" alt="" class="imageClass">
      <img src="../assets/about/8.jpg" alt="" class="imageClass">
      <img src="../assets/about/9.jpg" alt="" class="imageClass">
      <img src="../assets/about/10.jpg" alt="" class="mb40 imageClass">
    </el-row>
  </div>
</template>

<script>
// import AboutHeader from '../components/items/aboutHeader.vue'
import loginHeader from '../components/items/loginHeader.vue'
export default {
  name: 'About',
  components: {
    // AboutHeader
    loginHeader
  }
}
</script>

<style scoped>
img {
  width: 100wh;
  height: 100vh;
}
.imageClass {
  width: 100%;
  max-width: 1000px;
  height: auto;
}
</style>
