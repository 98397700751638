<template>
  <div>
    <el-header class="main-header" style="height:100px;">
      <el-row type="flex" justify="center" align="middle" class="cursor-pointer">
        <el-image style="width: 70px; height: 70px" :src="imgUrl" @click="handleLogo" v-if="imgUrl"></el-image>
        <span class="main-header-outlet-name ml30">{{displayName ? displayName : username}}</span>
      </el-row>
      <el-row type="flex" justify="center" align="middle" style="gap: 10px">
        <a target="_blank" href="https://www.toktok.mn/" class="main-header-button cursor-pointer">
          <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_25_974)">
            <path d="M1.05057 9.81696L3.86891 11.8753L2.79857 15.19C2.6256 15.7041 2.62341 16.2603 2.79233 16.7758C2.96124 17.2912 3.29221 17.7383 3.73591 18.0503C4.172 18.3723 4.70047 18.5448 5.24257 18.5421C5.78467 18.5394 6.31139 18.3616 6.74424 18.0352L9.50003 16.007L12.2566 18.0329C12.6919 18.3531 13.2175 18.527 13.7579 18.5296C14.2983 18.5322 14.8256 18.3635 15.264 18.0475C15.7024 17.7316 16.0293 17.2848 16.1978 16.7713C16.3662 16.2579 16.3675 15.7042 16.2015 15.19L15.1312 11.8753L17.9495 9.81696C18.3842 9.49911 18.7074 9.05201 18.8728 8.53951C19.0383 8.02702 19.0376 7.47535 18.8707 6.9633C18.7039 6.45126 18.3795 6.00502 17.944 5.68834C17.5084 5.37166 16.9839 5.20072 16.4453 5.19996H12.9834L11.9328 1.92562C11.7676 1.41021 11.443 0.960592 11.0058 0.641591C10.5685 0.32259 10.0413 0.150696 9.50003 0.150696C8.95879 0.150696 8.43154 0.32259 7.99431 0.641591C7.55707 0.960592 7.23244 1.41021 7.06724 1.92562L6.0167 5.19996H2.55791C2.01937 5.20072 1.49485 5.37166 1.05926 5.68834C0.62368 6.00502 0.299313 6.45126 0.132493 6.9633C-0.0343261 7.47535 -0.0350644 8.02702 0.130384 8.53951C0.295832 9.05201 0.619004 9.49911 1.05374 9.81696H1.05057Z" fill="#32CE56"/>
            </g>
            <defs>
            <clipPath id="clip0_25_974">
            <rect width="19" height="19" fill="white" transform="translate(0.000244141)"/>
            </clipPath>
            </defs>
          </svg>
          <span class="main-header-button-text">MARKET: Only on Tok Tok</span>
        </a>
        <button class="main-header-button cursor-pointer" @click="onClickFb">
          <svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="#1877F2"/>
          </svg>
          <span class="main-header-button-text">Харилцагчийн нэгдсэн групп</span>
        </button>
        <a target="_blank" href="https://online.fliphtml5.com/ggmst/qtpn/" class="main-header-button cursor-pointer">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.70801 11.8752H10.2913V13.4585H8.70801V11.8752ZM8.70801 5.54183H10.2913V10.2918H8.70801V5.54183ZM9.49967 1.5835C5.12176 1.5835 1.58301 5.146 1.58301 9.50016C1.58301 11.5998 2.41708 13.6134 3.90175 15.0981C4.63688 15.8332 5.5096 16.4164 6.4701 16.8142C7.43059 17.2121 8.46004 17.4168 9.49967 17.4168C11.5993 17.4168 13.6129 16.5828 15.0976 15.0981C16.5823 13.6134 17.4163 11.5998 17.4163 9.50016C17.4163 8.46053 17.2116 7.43108 16.8137 6.47058C16.4159 5.51009 15.8327 4.63736 15.0976 3.90223C14.3625 3.1671 13.4897 2.58397 12.5293 2.18612C11.5688 1.78827 10.5393 1.5835 9.49967 1.5835ZM9.49967 15.8335C7.81997 15.8335 6.20906 15.1662 5.02133 13.9785C3.8336 12.7908 3.16634 11.1799 3.16634 9.50016C3.16634 7.82046 3.8336 6.20955 5.02133 5.02182C6.20906 3.83409 7.81997 3.16683 9.49967 3.16683C11.1794 3.16683 12.7903 3.83409 13.978 5.02182C15.1657 6.20955 15.833 7.82046 15.833 9.50016C15.833 11.1799 15.1657 12.7908 13.978 13.9785C12.7903 15.1662 11.1794 15.8335 9.49967 15.8335Z" fill="#3A3A3C"/>
          </svg>
          <span class="main-header-button-text">Ашиглах заавар</span>
        </a>
        <button @click="dialogVisible = true" class="main-header-button cursor-pointer">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1613_13457)">
            <path d="M10.0003 3.06574C10.0024 3.19832 10.0432 3.32739 10.1177 3.43707C10.1922 3.54675 10.2972 3.63226 10.4196 3.68307C11.5145 4.16479 12.4403 4.96274 13.0783 5.97447C13.7163 6.98621 14.0374 8.16554 14.0003 9.36107C13.9745 10.9524 13.3176 12.4682 12.1741 13.5752C11.0307 14.6822 9.49428 15.2896 7.90298 15.2637C6.31168 15.2379 4.79581 14.581 3.68885 13.4376C2.58188 12.2941 1.9745 10.7577 2.00031 9.16641C2.00185 8.00389 2.34108 6.86685 2.97675 5.89352C3.61242 4.92019 4.51716 4.15249 5.58098 3.68374C5.70351 3.63262 5.80845 3.54686 5.88294 3.43696C5.95743 3.32706 5.99822 3.19782 6.00031 3.06507C6.00048 2.95588 5.97383 2.84831 5.9227 2.75183C5.87156 2.65535 5.79751 2.57291 5.70706 2.51175C5.6166 2.45059 5.5125 2.41258 5.40392 2.40107C5.29533 2.38956 5.18558 2.4049 5.08431 2.44574C3.53717 3.1165 2.26846 4.29997 1.49189 5.7968C0.715316 7.29363 0.478331 9.01237 0.820852 10.6635C1.16337 12.3146 2.06447 13.7973 3.37236 14.8617C4.68025 15.9261 6.31502 16.5073 8.00131 16.5073C9.6876 16.5073 11.3224 15.9261 12.6303 14.8617C13.9382 13.7973 14.8393 12.3146 15.1818 10.6635C15.5243 9.01237 15.2873 7.29363 14.5107 5.7968C13.7342 4.29997 12.4655 3.1165 10.9183 2.44574C10.8169 2.40446 10.7069 2.38879 10.5979 2.40013C10.489 2.41147 10.3846 2.44946 10.2938 2.51074C10.2031 2.57203 10.1289 2.65473 10.0777 2.75152C10.0265 2.84832 9.9999 2.95624 10.0003 3.06574Z" fill="#F24E1E"/>
            <path d="M8.66635 1.16667C8.66635 0.798477 8.36787 0.5 7.99968 0.5C7.63149 0.5 7.33301 0.798477 7.33301 1.16667V5.16667C7.33301 5.53486 7.63149 5.83333 7.99968 5.83333C8.36787 5.83333 8.66635 5.53486 8.66635 5.16667V1.16667Z" fill="#F24E1E"/>
            </g>
            <defs>
            <clipPath id="clip0_1613_13457">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
          </svg>
          <span style="color: #F24E1E;" class="main-header-button-text">Гарах</span>
        </button>
      </el-row>
    </el-header>
    <el-dialog
      title="Та гарах гэж байна!!!"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>Та итгэлтэй байна уу?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Үгүй</el-button>
        <el-button type="primary" @click="logout">Тийм</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import LogoutIcon from 'vue-material-design-icons/Logout'
import { signOut, getUser } from '../helper/auth'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LogoutIcon
  },
  data () {
    return {
      displayName: '',
      username: '',
      dialogVisible: false,
      imgUrl: ''
    }
  },
  async created () {
    if (this.$root.isAdmin) {
      const user = await getUser()
      this.username = user.username
    }
    if (this.$root.outlet !== null && this.$root.outlet.full_name_mon !== null) {
      this.displayName = this.$root.outlet.full_name_mon
      this.imgUrl = this.$root.outlet.logo_url ? this.$root.outlet.logo_url : this.$root.outlet.img_url
    }
  },
  methods: {
    handleLogo () {
      if (this.$route.path !== '/home') {
        this.$router.push('/')
      }
    },
    async logout () {
      signOut()
    },
    handleClose () {
      this.dialogVisible = false
    },
    onClickFb () {
      window.open('https://www.facebook.com/groups/toktokclient', '_blank')
    }
  }
}
</script>
<style scoped>

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
</style>
