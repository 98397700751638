import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { Message } from 'element-ui'
function getUser () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        return user
      } else {
        return null
      }
    })
    .catch(err => {
      console.log(err)
    })
}
function getOutlet () {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      if (user && user.signInUserSession) {
        const tempResponse = user.attributes['custom:es_id']
        if (tempResponse) {
          const supplierId = tempResponse
          return supplierId
        } else {
          return null
        }
      } else {
        return null
      }
    })
    .catch(err => {
      console.log('helper/getSupplierId', err)
      // alert(err, 'error')
    })
}
function isAdmin () {
  return Auth.currentAuthenticatedUser()
    .then(session => {
      if (session) {
        if (session && session.signInUserSession) {
          return session.signInUserSession.accessToken.payload['cognito:groups']
        } else {
          return null
        }
        // return session.idToken.payload['custom:isAdmin']
      } else {
        return null
      }
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    })
}

function signUp (username, email, password) {
  return Auth.signUp({
    username,
    password,
    attributes: {
      name: username,
      email: email
    }
  })
    .then(data => {
      return data
    })
    .catch(err => {
      userErrorMessageHandler(err)
    })
}
export async function sendForgotPassCode (username) {
  const data = await Auth.forgotPassword(username)
  return data
}

export async function changePasswordSumbit (payload) {
  const data = await Auth.forgotPasswordSubmit(payload.username, payload.code, payload.new_password)
  return data
}

async function confirmSignUp (username, code) {
  try {
    await Auth.confirmSignUp(username, code).then(data => {
      return data
    })
  } catch (error) {
    userErrorMessageHandler(error)
    throw new Error(error)
  }
}
async function completeNewPassword (password) {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.completeNewPassword(user, password).then(data => {
      return data
    })
  } catch (error) {
    userErrorMessageHandler(error)
    throw new Error(error)
  }
}

async function signIn (username, password) {
  try {
    const user = await Auth.signIn(username, password)
    // console.log('signIn', user)
    if (Object.prototype.hasOwnProperty.call(user, 'challengeName') && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      AmplifyEventBus.$emit('authState', 'newPasswordRequired')
    }
    // "NEW_PASSWORD_REQUIRED"
    // challengeParam
    if (user) {
      localStorage.setItem('clientData', JSON.stringify(user.attributes))
      AmplifyEventBus.$emit('authState', 'signedIn')
    }
  } catch (err) {
    userErrorMessageHandler(err)
  }
}

function userErrorMessageHandler (err) {
  if (err.code === 'UsernameExistsException') {
    alertReporter('Бүртгэлтэй хэрэглэгч байна', 'error')
  } else if (err.code === 'InvalidPasswordException') {
    alertReporter('Та том, жижиг үсэг, тэмдэгт оруулна уу!', 'error')
  } else if (err.code === 'UserNotConfirmedException') {
    alertReporter('Хэрэглэгчийг баталгаажуулаагүй байна. Админтай холбогдоно уу!', 'error')
  } else if (err.code === 'PasswordResetRequiredException') {
    alertReporter('Хэрэглэгчийг нууц үгийг сэргээх шаардлагтай.!', 'error')
  } else if (err.code === 'NotAuthorizedException') {
    alertReporter('Хэрэглэгчийн нууц үг эсвэл имэйл буруу байна.!!', 'error')
  } else if (err.code === 'UserNotFoundException') {
    alertReporter('Бүртгэлтэй хэрэглэгч олдсонгүй!!', 'error')
  } else if (err.code === 'InvalidParameterException') {
    alertReporter(`Алдаа гарлаа ${err.message}`, 'error')
  } else if (err.code === 'CodeMismatchException') {
    alertReporter('Алдаа гарлаа.!! Баталгаажуулах код буруу байна', 'error')
  } else {
    alertReporter(`Алдаа гарлаа.!! ${err.message}`, 'error')
  }
}
function alertReporter (message, type) {
  Message({
    message: message,
    duration: 2500,
    type: type,
    showClose: 1
  })
}
function signOut () {
  return Auth.signOut()
    .then(data => {
      localStorage.removeItem('clientData')
      AmplifyEventBus.$emit('authState', 'signedOut')
      return data
    })
    .catch(err => {
      console.log('auth/signOut', err)
      // alert(err, 'error')
    })
}
async function getToken () {
  return await Auth.currentSession()
    .then(session => {
      if (session) {
        return session.idToken.jwtToken
      } else {
        return null
      }
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    })
}
export {
  getUser,
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  getOutlet,
  getToken,
  isAdmin,
  completeNewPassword
}
